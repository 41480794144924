var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("headers"),
      _c("router-view", {
        directives: [
          {
            name: "wechat-title",
            rawName: "v-wechat-title",
            value: `${_vm.title}`,
            expression: "`${title}`",
          },
        ],
      }),
      _c("footers", [
        _vm.versionHash
          ? _c("span", { staticClass: "version" }, [
              _vm._v("version：" + _vm._s(_vm.versionHash)),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
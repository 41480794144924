<template>
	<div class="perspective" v-loading="listLoading">
		<div class="perspective-wrap" v-if="!listLoading">
			<ul :class="!type ? 'list' : 'grid-list'" v-if="pages.total !== 0">
				<li v-for="item in camerasList" :key="item.camera_id" class="item csp" @click="toView(item)">
					<img :src="item.latest_image" alt="缩略图" class="thumbnail" />
					<div class="info-wrap">
						<div class="info" style="flex: 1">
							<p class="name">{{ item.camera_name }}</p>
							<div class="other-info">
								<p class="region">{{ item.location.replace("｜", " ") }}</p>
								<p class="region">更新于：{{ item.latest_update_natural }}</p>
							</div>
						</div>
						<button class="unbind" @click.stop="unbind(item)">解绑</button>
					</div>
				</li>
			</ul>
			<empty v-if="pages.total == 0">
				<p>没有视角？前往 <button>商城</button> 购买观天派</p>
				<p>已安装观天派？前往 <button @click="toBinding">绑定</button></p>
			</empty>
			<div class="flex-align-between">
				<button class="binding-camera border-btn" @click="toBinding">视角绑定</button>
				<pagination
					v-if="pages.total"
					class="pages"
					:total="pages.total"
					:limit="pages.limit"
					:page.sync="pages.page"
					:layout="`prev, pager, next, jumper`"
					@pagination="handlePage"></pagination>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		type: {
			type: Number,
		},
	},
	data() {
		return {
			pages: {
				total: 0,
				page: 1,
				limit: 6,
			},
			listLoading: true,
			camerasList: [],
		};
	},
	computed: {
		viewMode() {
			return this.type === 0 ? "列表" : "卡片";
		},
	},
	mounted() {
		this.getCamerasList();
	},
	methods: {
		handlePage(data) {
			this.pages.page = data.pageIndex;
			this.getCamerasList();
		},
		getCamerasList() {
			this.listLoading = true;
			let params = {
				page: this.pages.page,
			};
			Api.Cameras.getCamerasList(params)
				.then((resData) => {
					// console.log(resData);
					this.camerasList = resData.data.content;
					this.pages.total = resData.data.total_count;
					this.listLoading = false;
				})
				.catch((error) => {
					// console.log("getCamerasList-----Error", error);
					this.listLoading = false;
				});
		},
		// 去视角详情
		toView(item) {
			this.$router.push({
				name: "camera",
				params: {
					id: item.camera_id,
				},
			});
		},
		// 去绑定页
		toBinding() {
			this.$router.push({
				name: "binding",
			});
		},
		// 接触绑定
		unbind(item) {
			this.$confirm("解绑将清空该视角的关注者，是否解绑？", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			})
				.then(() => {
					let params = {
						camera_id: item.camera_id,
					};
					console.log(params);
					Api.Cameras.unbind(params)
						.then((resData) => {
							if (resData.status == 204) {
								this.$message.success("解绑成功");
								this.getCamerasList();
							}
						})
						.catch((error) => {
							console.log(error);
							this.$message.error("解绑失败");
						});
				})
				.catch(() => {
					this.$message.info("取消解绑");
				});
		},
	},
};
</script>
<style lang="less" scoped>
.perspective {
	min-height: 287px;
	.perspective-wrap {
		min-height: 287px;
		display: flex;
		flex-direction: column;
	}

	.list {
		flex-grow: 1;

		.item {
			display: flex;
			align-items: center;
			border-bottom: 1px solid #e2e5ed;
			padding: 0 38px 0 31px;
			color: #000;

			&:last-child {
				border-bottom: 1px solid transparent;
			}

			&:hover {
				background: #f1faff;

				&:last-child {
					border-bottom: 1px solid #e2e5ed;
				}
			}
		}

		.thumbnail {
			width: 164px;
			height: 93px;
			margin: 16px 31px 16px 0;
		}

		.info-wrap {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
		}

		.name {
			display: inline-block;
			min-width: 330px;
		}

		.other-info {
			margin-top: 15px;

			.region {
				display: inline-block;
				color: #666;
				font-size: 12px;
				line-height: 24px;
				margin-right: 40px;
			}
		}

		.unbind {
			line-height: 24px;
			margin-bottom: 4px;
			font-size: 15px;
			color: #01aded;

			&:hover {
				color: rgba(1, 173, 237, 0.7);
			}
		}
	}

	.grid-list {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 20px;
		padding: 45px 60px 5px;

		.item {
			width: 100%;
			position: relative;

			&:hover .thumbnail {
				filter: brightness(85%);
			}
		}

		.thumbnail {
			display: block;
			width: 100%;
			height: 153px;
		}

		.info {
			border-radius: 0px 0px 4px 4px;
			background-color: #f2f2f2;
			padding: 7px 8px 7px 13px;
		}

		.name {
			font-weight: bold;
			color: #000;
			font-size: 15px;
		}

		.region {
			line-height: 24px;
			font-size: 12px;
			color: #666;
			display: inline-block;
		}

		.other-info {
			margin-top: 5px;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.unbind {
			position: absolute;
			bottom: 73px;
			right: 8px;
			border-radius: 16px;
			border: 1px solid #01aded;
			padding: 0 16px;
			height: 24px;
			line-height: 24px;
			color: #01aded;
			font-size: 13px;
			background-color: #fff;

			&:hover {
				background-color: #01aded;
				color: #fff;
			}
		}
	}

	.binding-camera {
		margin-left: 31px;
		margin-bottom: 20px;
	}

	.pages {
		text-align: right;
		margin-right: 65px;
	}
}
</style>

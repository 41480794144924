var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "best-plan" }, [
      _c("img", {
        attrs: {
          src: require("../../assets/images/common/point.png"),
          alt: "",
        },
      }),
      _c("img", {
        staticClass: "point-title",
        attrs: {
          src: require("../../assets/images/common/point-title.svg"),
          alt: "",
        },
      }),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "moudle" }, [
          _c("p", [
            _vm._v(" 欢迎您成为"),
            _c("strong", [_vm._v("观天者")]),
            _vm._v("社区的一员！为了感谢您的支持与参与，我们特设立了"),
            _c("strong", [_vm._v("积分体系")]),
            _vm._v(
              "。通过积分，您可以在我们的平台上享受更多专属福利和丰富奖励。以下是积分体系的详细说明： "
            ),
          ]),
        ]),
        _c("div", { staticClass: "moudle" }, [
          _c("h4", { staticClass: "moudle-title" }, [_vm._v("一、积分简介")]),
          _c("p", [
            _vm._v(
              " 积分是观天者平台内的虚拟代币，您可以通过多种方式获取积分，并用于兑换实体或虚拟商品、购物券，甚至提现。积分的积累与使用将为您带来更加丰富的使用体验和更多的惊喜奖励。 "
            ),
          ]),
        ]),
        _c("div", { staticClass: "moudle" }, [
          _c("h4", { staticClass: "moudle-title" }, [
            _vm._v("二、积分获取方式"),
          ]),
          _c("p", [_vm._v("您可以通过以下三种方式获取积分：")]),
          _c("ol", { staticClass: "list-ol" }, [
            _c("li", [
              _c("strong", [_vm._v("观天者精选：")]),
              _vm._v(
                " 通过购买或加盟形式安装“观天派”设备并申请“观天者精选”资格审核，在通过精选资格审核以后，每日正常拍摄并上传天气照片，即可获得固定数量的积分奖励。 "
              ),
            ]),
            _c("li", [
              _c("strong", [_vm._v("悬赏任务：")]),
              _vm._v(
                "平台定期发布各类“悬赏任务”，如常规天气分类、灾害天气识别等，完成任务可以获得相应的积分奖励。"
              ),
            ]),
            _c("li", [
              _c("strong", [_vm._v("充值兑换：")]),
              _vm._v(
                "通过平台提供的充值渠道，使用人民币按兑换比例购买积分包。"
              ),
            ]),
            _c("li", [
              _c("strong", [_vm._v("拉新奖励：")]),
              _vm._v(
                "用户推荐其他用户通过购买或加盟形式安装“观天派”设备，立刻获取 500 点积分。若被推荐的用户申请并入选到“观天者精选”，则推荐人可以每天持续获取一定点数的积分，持续 1 年。 "
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "moudle" }, [
          _c("h4", { staticClass: "moudle-title" }, [
            _vm._v("三、积分使用方式"),
          ]),
          _c("p", [_vm._v("积累的积分可用于以下多种用途：")]),
          _c("ol", { staticClass: "list-ol" }, [
            _c("li", [
              _c("p", [_c("strong", [_vm._v("素材下载")])]),
              _c("ul", { staticClass: "list-ul" }, [
                _c("li", [
                  _c("strong", [_vm._v("视频制作：")]),
                  _vm._v("使用积分将您的天气照片生成为精彩的视频素材。"),
                ]),
                _c("li", [
                  _c("strong", [_vm._v("积分消耗：")]),
                  _vm._v(
                    "：每生成一段视频需消耗一定数量的积分，具体消耗量请参考视频生成页面。"
                  ),
                ]),
              ]),
            ]),
            _c("li", [
              _c("p", [_c("strong", [_vm._v("兑换购物券")])]),
              _c("ul", { staticClass: "list-ul" }, [
                _c("li", [
                  _c("strong", [_vm._v("购物卡兑换：")]),
                  _vm._v("积分可用于兑换京东购物卡、天猫购物卡等多种礼品卡。"),
                ]),
                _c("li", [
                  _c("p", [_c("strong", [_vm._v("兑换流程：")])]),
                  _c("ol", { staticClass: "child-list-ol" }, [
                    _c("li", [_vm._v("登录账户，进入积分兑换页面。")]),
                    _c("li", [_vm._v("选择您希望兑换的购物卡类型和金额。")]),
                    _c("li", [
                      _vm._v(
                        "确认兑换，兑换成功后购物卡将通过邮件或短信发送至您的账户绑定信息。"
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _c("li", [
              _c("p", [_c("strong", [_vm._v("商城兑换商品")])]),
              _c("ul", { staticClass: "list-ul" }, [
                _c("li", [
                  _c("strong", [_vm._v("实体与虚拟商品：")]),
                  _vm._v(
                    "积分可直接用于兑换商城中上架的各类实体商品（如观天派配件）或虚拟商品（如专属滤镜、特效包）。 "
                  ),
                ]),
                _c("li", [
                  _c("p", [_c("strong", [_vm._v("兑换步骤：")])]),
                  _c("ol", { staticClass: "child-list-ol" }, [
                    _c("li", [_vm._v("访问商城。")]),
                    _c("li", [_vm._v("浏览并选择您感兴趣的商品。")]),
                    _c("li", [
                      _vm._v(
                        "使用积分完成兑换，商品将根据选择的配送方式寄送至您指定的地址。"
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "moudle" }, [
          _c("h4", { staticClass: "moudle-title" }, [
            _vm._v("精选计划的积分奖励"),
          ]),
          _c("p", [
            _vm._v(
              "无论您是预先申请还是后期申请，入选精选名录后都将享受以下积分奖励："
            ),
          ]),
          _c("ul", { staticClass: "list-ul" }, [
            _c("li", [
              _c("strong", [_vm._v("每日积分：")]),
              _vm._v("每天根据您的照片质量和展示情况，获得相应的积分。"),
            ]),
            _c("li", [
              _c("strong", [_vm._v("兑换福利：")]),
              _vm._v("积累的积分可用于兑换设备配件、服务升级或其他专属福利。"),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "moudle" }, [
          _c("h4", { staticClass: "moudle-title" }, [
            _vm._v("四、积分提现规则"),
          ]),
          _c("p", { staticStyle: { width: "853px" } }, [
            _vm._v(
              "为了保障平台的正常运营和用户的权益，积分提现需遵循以下规则："
            ),
          ]),
          _c("ul", { staticClass: "list-ul" }, [
            _c("li", [
              _c("strong", [_vm._v("提现资格：")]),
              _vm._v("用户需要完成个人实名认证和银行卡登记以获得提现资格。"),
            ]),
            _c("li", [
              _c("strong", [_vm._v("兑换比例：")]),
              _vm._v("1000 积分 = 1元人民币 提现方式：提交 提现申请。"),
            ]),
            _c("li", [
              _c("strong", [_vm._v("最低提现额度：")]),
              _vm._v("每次提现至少需达到10万点积分（100元人民币）。"),
            ]),
            _c("li", [
              _c("strong", [_vm._v("提现时间：")]),
              _vm._v("正常情况下将在每月最后5个工作日完成提现。"),
            ]),
            _c("li", [
              _c("strong", [_vm._v("税务提示：")]),
              _vm._v(
                "观天者网站的积分提现在税务上将被视为劳务报酬，每自然月提现超过800元时，平台将按照劳务税率代扣代缴个人所得税，用户可在“个人所得税 APP” 中查询到代缴记录。 "
              ),
            ]),
            _c("li", [
              _c("strong", [_vm._v("提现流程：")]),
              _c("br"),
              _c("p", [_vm._v("a. 登录账户，进入积分提现页面。")]),
              _c("p", [
                _vm._v("b. 选择提现方式和金额，确认信息无误后提交申请。"),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "moudle" }, [
          _c("h4", { staticClass: "moudle-title" }, [_vm._v("五、积分有效期")]),
          _c("ul", { staticClass: "list-ul" }, [
            _c("li", [
              _c("strong", [_vm._v("有效期限：")]),
              _vm._v("积分长期有效。"),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "moudle" }, [
          _c("h4", { staticClass: "moudle-title" }, [
            _vm._v("六、其他注意事项"),
          ]),
          _c("ul", { staticClass: "list-ul" }, [
            _c("li", [
              _c("strong", [_vm._v("防止积分滥用：")]),
              _vm._v(
                "禁止通过非正常手段获取积分，如发现违规行为，平台有权取消相关积分及账户资格。"
              ),
            ]),
            _c("li", [
              _c("strong", [_vm._v("积分调整：")]),
              _vm._v(
                "平台保留因系统维护或活动调整对积分规则进行变更的权利，变更将提前在官网公告。"
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "moudle" }, [
          _c("h4", { staticClass: "moudle-title" }, [_vm._v("七、常见问题")]),
          _c("h5", [_vm._v("Q1 ：如何查看我的积分余额？")]),
          _c("p", [
            _vm._v(
              "A1 ：登录您的观天者账户，进入个人主页 -> 积分模块，即可查看当前积分余额及获取记录。"
            ),
          ]),
          _c("h5", [_vm._v("Q2 ：积分是否可以转让给他人？")]),
          _c("p", [
            _vm._v("A2 ：为了确保积分体系的公平性，积分不可转让、赠予或出售。"),
          ]),
          _c("h5", [_vm._v("Q3 ：参与悬赏任务后未能通过审核，积分是否扣除？")]),
          _c("p", [
            _vm._v(
              "A3 ：仅在任务通过审核并确认完成后，积分奖励才会发放。未通过审核的任务将不奖励任何积分。"
            ),
          ]),
          _c("h5", [_vm._v("Q4 ：充值兑换的积分是否有使用限制？")]),
          _c("p", [
            _vm._v("A4 ：充值兑换的积分可用于平台内所有积分可用的用途。"),
          ]),
        ]),
        _c("div", { staticClass: "moudle" }, [
          _c("h4", { staticClass: "moudle-title" }, [_vm._v("八、联系我们")]),
          _c("p", { staticStyle: { width: "853px" } }, [
            _vm._v(
              "如果您对积分体系有任何疑问或建议，欢迎随时联系我们的客服团队："
            ),
          ]),
          _c("ul", { staticClass: "list-ul" }, [
            _c("li", [
              _c("strong", [_vm._v("客服邮箱：")]),
              _vm._v("support@skyviewor.com"),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "moudle" }, [
          _c("p", { staticClass: "thanks" }, [
            _vm._v("“感谢您选择"),
            _c("strong", [_vm._v("观天派，")]),
            _vm._v("期待与您一同记录美好时光，享受积分带来的丰富奖励！”"),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
    <div class="best-plan">
        <img src="../../assets/images/common/best.png" alt="">
        <img src="../../assets/images/common/best-title.svg" alt="">
        <div class="content"> 
            <div class="moudle">
                <p><strong>观天派</strong>致力于为您提供家用天气实景记录和延时摄影体验。为了进一步提升用户体验，我们特别推出了<strong>“观天者精选计划”</strong>。通过精选计划，您不仅可以展示独特的天气视角，还能获得丰厚的积分奖励。以下是精选计划的详细介绍：
                </p>
            </div>
            <div class="moudle">
                <h4 class="moudle-title">什么是“观天者精选计划”？</h4>
                <p>“观天者精选计划”是一个精选优美天气拍摄视角并向全网分享拍摄成果的计划。通过人工的审核，优质的视角和精彩的天气照片将被纳入“精选名录”。入选精选名录的用户每天都将获得积分奖励，享受更多专属福利。</p>
            </div>
            <div class="moudle">
                <h4 class="moudle-title">如何预先申请加入精选计划？</h4>
                <p>在购买和安装观天派之前，您可以提前申请加入精选计划。具体步骤如下： </p>
                <ol class="list-ol">
                    <li><strong>注册账号：</strong>登录观天者官方网站，注册账号。</li>
                    <li><strong>选择安装位置并拍摄外景照片：</strong>选择家中视野开阔的窗户作为安装位置，在计划安装位置拍摄几张外景照片。</li>
                    <li><strong>提交申请：</strong>点击 观天者精选计划申请 页面，按照说明提交相关资料完成审核。</li>
                    <li><strong>审核通过：</strong>我们的团队将对您的照片进行质量审核。</li>
                    <li><strong>享受优惠：</strong>一旦您的申请通过，即可获得购买观天派的折扣券，在购买观天派设备时享受优惠。</li>
                </ol>
                <h5 class="little-title">预先申请精选计划的优势：</h5>
                <ul class="list-ul">
                    <li><strong>专属折扣价：</strong>提前获得精选资格的用户可享受设备购买优惠，具体优惠价格按申请结果为准。</li>
                    <li><strong>持续积分奖励：</strong>入选精选名录后，每天正常拍摄都将获得持续的积分奖励，累积更多福利。</li>
                </ul>
            </div>
            <div class="moudle">
                <h4 class="moudle-title">安装后如何申请加入精选计划？</h4>
                <p>如果您在购买和安装观天派后才决定加入精选计划，也无需担心。您仍然可以通过以下方式轻松申请： </p>
                <ol class="list-ol">
                    <li><strong>一键申请：</strong> 在设备安装并开始拍摄后，点击视角详情里的“一键申请”按钮提交您的申请。</li>
                </ol>
                <h5 class="little-title">后期申请精选计划的优势：</h5>
                <ul class="list-ul">
                    <li><strong>即时奖励：</strong>审核通过后，您将获得一次性积分奖励，具体数额按申请结果为准。</li>
                    <li><strong>持续收益：</strong>入选精选名录后，每天正常拍摄都将获得持续的积分奖励，累积更多福利。</li>
                </ul>
            </div>
            <div class="moudle">
                <h4 class="moudle-title">精选计划的积分奖励</h4>
                <p>无论您是预先申请还是后期申请，入选精选名录后都将享受以下积分奖励：</p>
                <ul class="list-ul">
                    <li><strong>每日积分：</strong>每天根据您的照片质量和展示情况，获得相应的积分。</li>
                    <li><strong>兑换福利：</strong>积累的积分可用于兑换设备配件、服务升级或其他专属福利。</li>
                </ul>
            </div>
            <div class="moudle">
                <h4 class="moudle-title">如何提高审核通过率</h4>
                <p style="width:853px">观天者精选计划的目的是筛选出风景优美或有利于观测天气状况的拍摄视角并向社区分享。因此我们希望您的拍摄视角能尽量满足以下条件： </p>
                <ul class="list-ul">
                    <li>视野开阔，以中高层建筑为佳。 </li>
                    <li>无遮挡物，没有大片的树木或者建筑物遮挡天空，没有窗户护栏影响视线。</li>
                    <li>无强光影响，拍摄方向尽量避免正南方向（易受太阳强光影响，镜头成像质量不稳定）。</li>
                </ul>
            </div>
            <div class="moudle">
                <h4 class="moudle-title">立即行动，成为“观天者”精选用户！</h4>
                <p style="width:853px">无论您是天气摄影爱好者还是热衷于记录天气变化的用户，“观天者精选计划”都将为您带来前所未有的体验和奖励。现在就加入我们，展示您的独特视角，赢取丰厚积分奖励！
                </p>
                <p class="more"><strong>更多详情，请访问我们的观天者精选计划页面。</strong></p>
            </div>
            <div class="moudle">
                <p class="thanks">“感谢您选择<strong>观天派，</strong>让我们一起记录每一个美丽的瞬间！”</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {

    }
</script>

<style lang="less" scoped>
    .best-plan {
        padding: 75px 0 100px;

        &>img {
            display: block;
            margin: 0 auto 15px;
        }

        .content {
            width: 963px;
            margin: 0 auto;
            margin-top: 65px;
        }

        .moudle {
            padding: 35px 0;
            border-bottom: 1px solid #E2E5ED;
            color: #333;
            font-size: 17px;
            font-style: normal;
            line-height: 36px;

            &:last-child {
                border-bottom: 0;
            }

            .moudle-title {
                font-size: 20px;
                margin-bottom: 10px;
                font-weight: 600;
                line-height: 40px;
                color: #181818;
            }

            .little-title {
                font-size: 17px;
                font-weight: 600;
            }

            .list-ol,
            .list-ul {
                margin-left: 1em;
            }

            .list-ol {
                margin-left: 1.3em
            }

            .list-ol li {
                list-style: auto;

            }

            .list-ul li {
                list-style: disc;
            }

            .thanks {
                color: #007ec4;
            }

        }
    }
</style>
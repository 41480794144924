<template>
	<!-- 视角详情 控制台模块 -->
	<div class="controller">
		<div class="toolbar flex-align">
			<div class="left">
				<div class="time flex-align">
					<span class="tool-name" style="flex-shrink: 0">时间</span>
					<el-date-picker
						class="date-picker"
						v-model="timeValue"
						type="datetimerange"
						range-separator="-"
						start-placeholder="开始时间"
						end-placeholder="结束时间"
						prefix-icon="el-icon-date"
						popper-class="video-time-picker"
						value-format="timestamp"
						@change="changeDatePicker">
					</el-date-picker>
				</div>
				<div class="other-tool flex-align">
					<div class="other-item duration">
						<span class="tool-name">时长</span>
						<el-select
							v-model="durationValue"
							placeholder="请选择"
							popper-class="type-select"
							:popper-append-to-body="true"
							@change="changeDuration">
							<el-option v-for="item in durationList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
						</el-select>
					</div>
					<div class="other-item interval">
						<span class="tool-name">间隔</span>
						<el-select
							v-model="intervalValue"
							placeholder="请选择"
							popper-class="type-select"
							:popper-append-to-body="true"
							@change="changeInterval">
							<el-option v-for="item in intervalList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
						</el-select>
					</div>
					<div class="other-item resolution">
						<span class="tool-name">分辨率</span>
						<el-select
							v-model="resolutionValue"
							placeholder="请选择"
							popper-class="type-select"
							:popper-append-to-body="true"
							@change="changeResolution">
							<el-option v-for="item in resolutionList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
						</el-select>
					</div>
				</div>
			</div>
			<div class="rigth">
				<el-checkbox-group class="checkbox-group" v-model="checkList" @change="changeCheck">
					<el-checkbox label="观天者水印"></el-checkbox>
					<el-checkbox label="天气类型"></el-checkbox>
					<el-checkbox label="用户名水印"></el-checkbox>
					<el-checkbox label="时间信息"></el-checkbox>
				</el-checkbox-group>
			</div>
		</div>
		<div class="release flex-align">
			<p>生成视频消耗积分：{{ point }}<button class="howToPoints csp">如何获取积分?</button></p>
			<button class="export-video-btn csp" @click="openTips">视频生成</button>
		</div>

		<el-dialog :visible.sync="dialogVisible" width="450px" :before-close="handleClose" class="tipsDialog">
			<span>生成视频将消耗积分：{{ point }}</span>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="checkPoint">确定</el-button>
			</span>
		</el-dialog>

		<el-dialog :visible.sync="nextDialogVisible" width="450px" :before-close="handleClose" class="tipsDialog">
			<span>视频生成任务已提交，请稍后前往<button class="album" @click="toAlbum">影集</button>查看</span>
			<span slot="footer" class="dialog-footer">
				<el-button @click="closeDialog">返回</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
export default {
	props: {
		refresh: {
			type: Boolean,
		},
	},
	data() {
		return {
			checkList: ["观天者水印", "天气类型", "用户名水印", "时间信息"],
			timeValue: undefined,
			dialogVisible: false,
			nextDialogVisible: false,
			durationValue: 1800,
			durationList: [
				{
					value: 1800,
					label: "最近30分钟",
				},
				{
					value: 3600,
					label: "最近1小时",
				},
				{
					value: 10800,
					label: "最近3小时",
				},
				{
					value: 21600,
					label: "最近6小时",
				},
				{
					value: 86400,
					label: "最近24小时",
				},
				{
					value: 259200,
					label: "最近3天",
				},
				{
					value: 2592000,
					label: "最近30天",
				},
				{
					value: 15552000,
					label: "最近180天",
				},
				{
					value: 31536000,
					label: "最近365天",
				},
			],
			resolutionValue: 1,
			resolutionList: [
				{
					value: 1,
					label: "原画",
				},
				{
					value: 2,
					label: "高清",
				},
				{
					value: 3,
					label: "普通",
				},
			],
			intervalValue: undefined,
			intervalList: [],
			point: 0,
		};
	},
	watch: {
		refresh(newVal, oldVal) {
			// console.log("isRefresh", newVal);
			if (newVal) {
				this.changeDuration(1800);
			}
		},
	},
	methods: {
		changeCheck(val) {
			this.$emit("changeCheck", val);
		},
		openTips() {
			this.dialogVisible = true;
		},
		checkPoint() {
			this.videoMake();
		},
		closeDialog() {
			this.dialogVisible = false;
			this.nextDialogVisible = false;
		},
		toAlbum() {
			this.$router.push({name:'album'})
		},

		// 自定义事件
		changeDatePicker(val) {
			console.log("自定义事件", val);
			// 时间差值 结束时间-开始时间
			let dvalue = val[1] / 1000 - val[0] / 1000;
			// console.log("时间差值", dvalue);
			this.durationValue = undefined;
			this.durationList.map((item) => {
				if (dvalue == item.value) {
					this.durationValue = item.value;
				}
			});

			let rangeObj = {
				start_time: val[0] / 1000,
				end_time: val[1] / 1000,
			};
			this.getCameraInterval(rangeObj);
		},
		// 修改时间区间（时长）
		changeDuration(val) {
			// console.log("时间区间", val);
			let now = this.dayjs().unix();
			// console.log("当前时间戳-结束时间戳", now);
			let startTime = now - val;
			// console.log("开始时间戳", startTime);
			this.timeValue = [startTime * 1000, now * 1000];
			let rangeObj = {
				last_tiem_range: this.durationValue,
			};
			this.intervalValue = undefined;
			setTimeout(() => {
				this.getCameraInterval(rangeObj);
			}, 100);
		},
		// 修改时间间隔
		changeInterval(val) {
			if (val) {
				this.backDataToView();
			}
		},
		// 修改分辨率
		changeResolution(val) {
			console.log("分辨率", val);
			if (val) {
				this.requestConsumption();
			}
		},
		// 获取时间间隔
		getCameraInterval(range) {
			this.intervalList = [];
			let params = {
				start_time: this.timeValue[0] / 1000,
				end_time: this.timeValue[1] / 1000,
				last_time_range: range,
			};
			Api.Cameras.getCameraInterval(params).then((res) => {
				// console.log("间隔", res.data.interval);
				if (res.status == 200) {
					res.data.interval.map((item) => {
						let labelStr = "";
						if (item <= 60) {
							labelStr = `${item}秒`;
						} else if (item > 60 && item < 60 * 60) {
							labelStr = `${item / 60}分钟`;
						} else if (item >= 60 * 60 && item < 60 * 60 * 24) {
							labelStr = `${item / (60 * 60)}小时`;
						} else if (item >= 60 * 60 * 24) {
							labelStr = `${item / (60 * 60 * 24)}天`;
						}
						this.intervalList.push({
							value: item,
							label: labelStr,
						});
					});
					this.intervalValue = this.intervalList[0].value;
					this.backDataToView();
				}
			});
		},
		// 返回数据给view组件
		backDataToView() {
			if (!this.intervalValue) {
				return;
			}
			let data = {
				camera_id: this.$route.params.id,
				start_time: this.timeValue[0] / 1000,
				end_time: this.timeValue[1] / 1000,
				time_interval: this.intervalValue,
				// last_time_range: {
				// 	start_time: this.timeValue[0] / 1000,
				// 	end_time: this.timeValue[1] / 1000,
				// },
				resolution: this.resolutionValue,
			};
			this.$emit("timePicke", data);
			this.requestConsumption();
		},
		// 查询生成视频需要消耗的积分
		requestConsumption() {
			let params = {
				start_time: this.timeValue[0] / 1000,
				end_time: this.timeValue[1] / 1000,
				time_interval: this.intervalValue,
				resolution: this.resolutionValue,
				// last_time_range: {
				// 	start_time: this.timeValue[0] / 1000,
				// 	end_time: this.timeValue[1] / 1000,
				// },
			};
			Api.Cameras.requestConsumption(params)
				.then((res) => {
					// console.log("消耗的积分", res.data);
					if (res.status == 200) {
						this.point = res.data.consumption;
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
		// 生成视频
		videoMake() {
			let params = {
				camera_id: this.$route.params.id,
				start_time: this.timeValue[0] / 1000,
				end_time: this.timeValue[1] / 1000,
				time_interval: this.intervalValue,
				resolution: this.resolutionValue,
				business_watermark: this.checkList.includes("观天者水印"),
				weather_type: this.checkList.includes("天气类型"),
				user_watermark: this.checkList.includes("用户名水印"),
				time_info: this.checkList.includes("时间信息"),
			};
			console.log(params);
			Api.Cameras.videoMake(params)
				.then((resData) => {
					if (resData.status == 201) {
						console.log(resData.data);
						this.nextDialogVisible = true;
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
	mounted() {
		this.changeDuration(1800);
	},
};
</script>

<style lang="less" scoped>
.controller {
	padding: 60px 75px 50px;

	.left {
		padding-right: 45px;

		.other-tool {
			margin-top: 27px;
		}

		.duration .el-select {
			width: 115px;
		}

		.interval .el-select {
			width: 106px;
		}

		/deep/.el-select {
			width: 100px;
			&:hover .el-input__inner {
				background-color: #f7f7f7;
			}

			.el-input__inner {
				border: none;
				background-color: #f7f7f7;
				color: #333;
				font-size: 12px;
				padding: 0 10px;
				height: 24px;
				border-radius: 23px;
				text-align: center;
			}

			.el-select__caret {
				color: #000;
				font-size: 12px;
				line-height: 24px;
				border-radius: 23px;
			}

			.el-input__suffix {
				right: 0;
			}
		}

		.other-item {
			margin-right: 30px;

			&.resolution {
				margin-right: 0;
			}
		}

		.tool-name {
			margin-right: 12px;
		}
	}

	.rigth {
		border-left: 1px solid #e2e5ed;
		padding: 8px 0 5px 45px;
	}

	.release {
		margin-top: 60px;
		.howToPoints {
			margin-left: 18px;
			color: #01aded;

			&:hover {
				text-decoration: underline;
			}
		}

		.export-video-btn {
			border-radius: 6px;
			background: #01aded;
			padding: 0 22px;
			line-height: 32px;
			color: #fff;
			margin-left: 110px;

			&:hover {
				background: #4ec5f1;
			}
		}
	}
}
.date-picker {
	width: 100%;
	::v-deep&.el-range-editor.el-input__inner {
		border: 1px solid #f7f7f7;
		background-color: #f7f7f7;
		border-radius: 30px;
		line-height: 30px;
		height: 30px;
		padding: 3px 11px;

		&:hover {
			background-color: #e7e7e7;
			border: 1px solid #e7e7e7;

			.el-range-input {
				background-color: #e7e7e7;
			}
		}

		.el-range-input {
			background-color: #f7f7f7;
		}

		.el-range-separator,
		.el-input__icon {
			line-height: 23px;
		}
	}
}

.checkbox-group {
	display: grid;
	grid-template-columns: repeat(2, 1fr); /* 每行两个列 */
	gap: 34px 26px; /* 列之间的间距 */

	/deep/.el-checkbox {
		margin-right: 0;
	}

	/deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
		color: #333;
	}

	/deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
	.el-checkbox__input.is-indeterminate .el-checkbox__inner {
		background-color: #01aded;
		border-color: #01aded;
	}

	/deep/ .el-checkbox__inner:hover {
		border-color: #01aded;
	}

	/deep/ .is-checked:hover .el-checkbox__inner {
		background-color: #fff;
		&::after {
			border: 1px solid #01aded;
			border-left: 0;
			border-top: 0;
		}
	}
}

.tipsDialog {
	/deep/ .el-dialog__body {
		color: #000;
		font-size: 16px;
		font-weight: 600;
		line-height: 28px;
		text-align: center;

		.album {
			color: #01aded;
			cursor: pointer;

			&:hover {
				color: rgba(1, 173, 237, 0.65);
			}
		}
	}

	/deep/ .el-dialog__footer {
		text-align: center;
		padding: 27px 20px 36px;
		.dialog-footer .el-button--primary {
			border-radius: 10px;
			background: #01aded;
			line-height: 40px;
			padding: 0 44px;
			font-size: 16px;

			&:hover {
				background: #4ec5f1;
			}
		}

		.dialog-footer .el-button--default {
			border: 0;
			color: #999;
			&:hover {
				background: transparent;
				color: rgba(153, 153, 153, 0.6);
			}
		}
	}
}
</style>

<style lang="less">
.type-select {
	background-color: #f7f7f7;

	.el-select-dropdown__item.selected {
		background-color: #01aded;
		color: #fff;
	}

	&.el-select-dropdown.el-popper{
		border-radius: 10px;
		box-shadow: 0px 0px 8px 0px rgba(100, 110, 144, 0.15);
		border: 0;
		margin-top: 4px;
		line-height: 36px;
		overflow: hidden;
		text-align: center;

		.el-select-dropdown__list {
			padding: 10px 0;
		}

		.el-select-dropdown__item {
			&:hover {
				background: #01aded;
				color: #fff;
			}
		}
	}
}

.video-time-picker {
	border: 1px solid #e5e6eb;
	color: #23272f;
	.el-button.is-plain {
		border: 0;
		background-color: #01aded;
		color: #fff;

		&:hover {
			background-color: #4ec5f1;
		}
	}

	.el-date-table td.available:hover {
		color: #01aded;
	}

	.el-date-table td.end-date span,
	.el-date-table td.start-date span {
		background-color: #01aded;
	}

	.el-date-table td.in-range div,
	.el-date-table td.in-range div:hover,
	.el-date-table.is-week-mode .el-date-table__row.current div,
	.el-date-table.is-week-mode .el-date-table__row:hover div {
		background-color: #d3efff;
	}

	.el-date-table td.today span {
		color: #01aded;
	}

	.el-date-table td.today.end-date span,
	.el-date-table td.today.start-date span {
		color: #fff;
	}

	.el-date-range-picker__content.is-left {
		border-right: 0;
	}

	.el-date-table th {
		border-bottom: 0;
		text-align: center;
	}

	.el-date-range-picker__header {
		// border-bottom: 1px solid #e5e6eb;
		padding: 5px 10px 20px;

		& button:nth-child(2) {
			&.el-icon-arrow-right {
				margin-right: 20px;
			}

			&.el-icon-arrow-left {
				margin-left: 20px;
			}
		}
	}

	.el-date-range-picker__content {
		position: relative;
		&::before {
			content: "";
			display: block;
			position: absolute;
			height: 1px;
			width: 100%;
			background-color: #e5e6eb;
			left: 0;
			top: 60px;
		}
	}

	.el-date-range-picker__time-header {
		position: absolute;
		bottom: 50px;
		border-bottom: 1px solid #e5e6eb;
		border-top: 1px solid #e5e6eb;
		padding: 0;
		.el-icon-arrow-right {
			display: none;
		}

		.is-right {
			& .el-date-range-picker__time-picker-wrap:nth-child(2) {
				border-right: 0;
			}
		}

		.el-date-range-picker__editors-wrap {
			.el-date-range-picker__time-picker-wrap:nth-child(2)::before {
				content: "\e71f";
			}
		}

		.el-date-range-picker__time-picker-wrap {
			padding: 5px 5px 5px 20px;
			border-right: 1px solid #e5e6eb;
			.el-input__inner {
				border: 0;
			}

			&::before {
				position: absolute;
				left: 10px;
				top: 50%;
				transform: translateY(-55%);
				z-index: 99;
				content: "\e78e";
				font-size: 15px;
				font-family: element-icons !important;
				font-style: normal;
				font-weight: 400;
				font-variant: normal;
				text-transform: none;
				line-height: 1;
				display: inline-block;
				-webkit-font-smoothing: antialiased;
			}
		}

		.el-input.is-disabled .el-input__inner {
			background-color: #fff;
		}
	}

	.el-picker-panel__footer {
		margin-top: 50px;
		border-top: 0;
	}

	.el-time-panel {
		width: 160px;
	}

	.el-time-panel__btn.confirm {
		color: #01aded;
	}
}
</style>

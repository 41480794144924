var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "binding" },
    [
      _c(
        "div",
        { ref: "back", staticClass: "back" },
        [
          _c(
            "el-button",
            {
              staticClass: "back-btn",
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "form-wrap" },
        [
          _c(
            "el-form",
            {
              ref: "bindingForm",
              attrs: {
                model: _vm.bindingForm,
                rules: _vm.bindingFormRules,
                "hide-required-asterisk": "",
                inline: "",
                "label-position": "top",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "sn_code",
                  attrs: { prop: "SNcode", label: "S/N 码" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "350px" },
                    attrs: { placeholder: "请输入 SN 码进行观天派视角绑定" },
                    model: {
                      value: _vm.bindingForm.SNcode,
                      callback: function ($$v) {
                        _vm.$set(_vm.bindingForm, "SNcode", $$v)
                      },
                      expression: "bindingForm.SNcode",
                    },
                  }),
                  _vm.verifyIcon
                    ? _c("i", {
                        class: _vm.snCorrect
                          ? "el-icon-success"
                          : "el-icon-warning",
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c("div", { staticClass: "sketch" }, [
                _c("p", { staticClass: "sketch-tips" }, [
                  _vm._v("如何查找设备的 SN 码"),
                ]),
                _c("img", {
                  staticClass: "sketch-image",
                  attrs: { src: require("@/assets/images/common/SNcode.jpg") },
                }),
              ]),
              _c(
                "el-form-item",
                {
                  staticClass: "cameraName",
                  attrs: { prop: "cameraName", label: "视角名称" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "350px" },
                    attrs: {
                      placeholder: "请输入视角名称",
                      maxlength: "15",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.bindingForm.cameraName,
                      callback: function ($$v) {
                        _vm.$set(_vm.bindingForm, "cameraName", $$v)
                      },
                      expression: "bindingForm.cameraName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "city",
                  attrs: { prop: "city", label: "所在地区" },
                },
                [
                  _c("el-cascader", {
                    staticStyle: { width: "350px" },
                    attrs: {
                      placeholder: "未设置",
                      options: _vm.cityOptions,
                      popperClass: "city-choose",
                      "append-to-body": false,
                      props: { value: "label", emitPath: true },
                    },
                    on: { change: _vm.cityChange },
                    model: {
                      value: _vm.bindingForm.city,
                      callback: function ($$v) {
                        _vm.$set(_vm.bindingForm, "city", $$v)
                      },
                      expression: "bindingForm.city",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "button",
            { staticClass: "submit-binding", on: { click: _vm.submitForm } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: `视角 ${_vm.bindingForm.cameraName} 已绑定成功`,
            visible: _vm.visibleSuccessDialog,
            "append-to-body": true,
            "show-close": true,
            width: "450px",
            "destroy-on-close": true,
            "close-on-click-modal": false,
            "custom-class": "successDialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visibleSuccessDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.finishBinding },
                },
                [_vm._v("继续绑定")]
              ),
              _c(
                "button",
                {
                  staticClass: "back csp",
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                },
                [_vm._v("返回视角列表")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
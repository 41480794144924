var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "coummunity", staticClass: "coummunity" }, [
    _c(
      "div",
      { staticClass: "main flex-layout" },
      [
        _c("div", { ref: "sidebar", staticClass: "sidebar" }, [
          _c("div", [
            _c("div", { staticClass: "sidebar-title" }, [_vm._v("社区")]),
            _c(
              "ul",
              { staticClass: "sidebar-list" },
              _vm._l(_vm.list, function (item, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    staticClass: "sidebar-list-item csp",
                    class: { active: _vm.currentName == item.name },
                    on: {
                      click: function ($event) {
                        return _vm.swichItem(item)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(item.name) + " ")]
                )
              }),
              0
            ),
          ]),
          _c("div", { staticClass: "sidebar-ad" }, [_vm._v("广告位")]),
        ]),
        _c("router-view", { staticClass: "coummunity-view" }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
	<div class="find">
		<div class="sort-bar flex-align-between">
			<div class="selected-wrap flex-align-around">
				<div class="selected-moudle">
					<span class="select-name">国家</span>
					<el-select v-model="country" placeholder="未设置" clearable popper-class="city-select" :popper-append-to-body="false">
						<el-option key="1" label="中国" value="中国"></el-option>
					</el-select>
				</div>
				<div class="selected-moudle">
					<span class="select-name">省/州</span>
					<el-select v-model="province" placeholder="未设置" clearable @change="changeProvince" :popper-append-to-body="false">
						<el-option v-for="item in chinaOptions" :key="item.value" :label="item.label" :value="item.label"> </el-option>
					</el-select>
				</div>
				<div class="selected-moudle">
					<span class="select-name">城市</span>
					<el-select v-model="city" placeholder="未设置" clearable :popper-append-to-body="false">
						<el-option v-for="item in cityOptions" :key="item.value" :label="item.label" :value="item.label"> </el-option>
					</el-select>
				</div>
			</div>
			<button class="submit-btn" @click="getList">筛选</button>
		</div>
		<div class="list-wrap" v-loading="listLoading">
			<div class="list-wrap-box" v-if="!listLoading">
				<empty v-if="pages.total == 0">
					<p>暂无数据</p>
				</empty>
				<ul class="grid-list" v-if="pages.total !== 0">
					<li v-for="item in camerasList" :key="item.camera_id" class="item csp" @click="toView(item)">
						<img :src="item.latest_image" alt="缩略图" class="thumbnail" />
						<div class="info">
							<div class="flex-align-between">
								<p class="name">{{ item.camera_name }}</p>
								<p class="own wes" :title="item.owner_user_name">@{{ item.owner_user_name }}</p>
							</div>
							<div class="flex-align-between" style="margin-top: 10px">
								<p class="region">{{ item.location.replace("｜", " ") }}</p>
								<p class="region updateTime">更新于:{{ item.latest_update_natural }}</p>
							</div>
						</div>
					</li>
				</ul>
				<pagination
					v-if="pages.total"
					class="pages"
					:total="pages.total"
					:limit="pages.limit"
					:page.sync="pages.page"
					:layout="`prev, pager, next, jumper`"
					@pagination="handlePage"></pagination>
			</div>
		</div>
	</div>
</template>

<script>
import citysJson from "/static/ChinaCitysAll.json";
export default {
	data() {
		return {
			country: "中国",
			province: "",
			city: "",
			chinaOptions: citysJson,
			cityOptions: [],
			pages: {
				total: 0,
				page: 1,
				limit: 9,
			},
			listLoading: true,
			camerasList: [],
		};
	},
	computed() {},
	methods: {
		init() {
			this.getList();
		},
		handlePage(data) {
			this.pages.page = data.pageIndex;
			this.getList();
		},
		getList() {
			this.listLoading = true;
			let params = {
				country: this.country,
				province: this.province,
				city: this.city,
				page: this.pages.page,
				page_size: this.pages.limit,
			};
			Api.Cameras.getDiscoverList(params)
				.then((resData) => {
					console.log(resData);
					if (resData.status == 200) {
						this.camerasList = resData.data.content;
						this.pages.total = resData.data.total_count;
						this.listLoading = false;
					}
				})
				.catch((error) => {
					coonsole.log(error);
					this.listLoading = false;
				});
		},
		changeProvince(val) {
			console.log(val);
			this.city = "";
			this.cityOptions = [];
			if (!val) {
				return;
			}
			this.chinaOptions.map((item) => {
				if (item.label == val) {
					this.cityOptions = item.children;
				}
			});
		},
		toView(item) {
			this.$router.push({
				name: "camera",
				params: {
					id: item.camera_id,
				},
			});
		},
	},
	mounted() {
		this.init();
	},
};
</script>

<style lang="less" scoped>
.find {
	border-radius: 10px;
	background: #fff;
	width: 100%;
	overflow: hidden;

	.sort-bar {
		border-radius: 12px;
		background: linear-gradient(132deg, #f6feff 29.7%, #dff4ff 69.41%, #f6feff 98.88%);
		height: 100px;
		padding: 0 60px;

		.selected-moudle {
			margin-right: 27px;

			.select-name {
				font-weight: 600;
				font-size: 15px;
				margin-right: 9px;
			}
		}

		/deep/ .el-select {
			width: 179px;
			.el-input__inner {
				border-radius: 10px;
				border: 0;
			}
		}
	}

	.list-wrap {
		height: 860px;

		.list-wrap-box {
			display: flex;
			flex-direction: column;
			height: 100%;
		}
	}

	.grid-list {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-template-rows: repeat(3, 1fr);
		gap: 20px;
		padding: 50px 60px 50px;
		min-height: 680px;

		.item {
			width: 100%;

			&:hover .thumbnail {
				filter: brightness(85%);
			}
		}

		.thumbnail {
			display: block;
			width: 100%;
			height: 153px;
		}

		.info {
			border-radius: 0px 0px 4px 4px;
			background-color: #f2f2f2;
			padding: 7px 8px 7px 13px;
		}

		.name {
			font-weight: bold;
			color: #000;
			font-size: 15px;
		}

		.region {
			color: #666;
			// text-align: right;
			font-size: 12px;
			line-height: 24px;
		}

		.own {
			max-width: 120px;
		}
	}

	.pages {
		text-align: right;
		margin-right: 65px;
	}
}
</style>

<style></style>

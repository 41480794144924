var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.listLoading,
          expression: "listLoading",
        },
      ],
      staticClass: "perspective",
    },
    [
      !_vm.listLoading
        ? _c(
            "div",
            { staticClass: "perspective-wrap" },
            [
              _vm.pages.total !== 0
                ? _c(
                    "ul",
                    { class: !_vm.type ? "list" : "grid-list" },
                    _vm._l(_vm.camerasList, function (item) {
                      return _c(
                        "li",
                        {
                          key: item.camera_id,
                          staticClass: "item csp",
                          on: {
                            click: function ($event) {
                              return _vm.toView(item)
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "thumbnail",
                            attrs: { src: item.latest_image, alt: "缩略图" },
                          }),
                          _c("div", { staticClass: "info-wrap" }, [
                            _c(
                              "div",
                              {
                                staticClass: "info",
                                staticStyle: { flex: "1" },
                              },
                              [
                                _c("p", { staticClass: "name" }, [
                                  _vm._v(_vm._s(item.camera_name)),
                                ]),
                                _c("div", { staticClass: "other-info" }, [
                                  _c("p", { staticClass: "region" }, [
                                    _vm._v(
                                      _vm._s(item.location.replace("｜", " "))
                                    ),
                                  ]),
                                  _c("p", { staticClass: "region" }, [
                                    _vm._v(
                                      "更新于：" +
                                        _vm._s(item.latest_update_natural)
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "button",
                              {
                                staticClass: "unbind",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.unbind(item)
                                  },
                                },
                              },
                              [_vm._v("解绑")]
                            ),
                          ]),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm.pages.total == 0
                ? _c("empty", [
                    _c("p", [
                      _vm._v("没有视角？前往 "),
                      _c("button", [_vm._v("商城")]),
                      _vm._v(" 购买观天派"),
                    ]),
                    _c("p", [
                      _vm._v("已安装观天派？前往 "),
                      _c("button", { on: { click: _vm.toBinding } }, [
                        _vm._v("绑定"),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "flex-align-between" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "binding-camera border-btn",
                      on: { click: _vm.toBinding },
                    },
                    [_vm._v("视角绑定")]
                  ),
                  _vm.pages.total
                    ? _c("pagination", {
                        staticClass: "pages",
                        attrs: {
                          total: _vm.pages.total,
                          limit: _vm.pages.limit,
                          page: _vm.pages.page,
                          layout: `prev, pager, next, jumper`,
                        },
                        on: {
                          "update:page": function ($event) {
                            return _vm.$set(_vm.pages, "page", $event)
                          },
                          pagination: _vm.handlePage,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "points",
    },
    [
      !_vm.isLoading
        ? _c("div", { staticClass: "points-wrap" }, [
            _c("div", { staticClass: "top-chat" }, [
              _c("p", { staticClass: "current-point" }, [
                _vm._v("当前积分   " + _vm._s(_vm.point) + " "),
                _c("span", [_vm._v("近30天积分变化")]),
              ]),
              _c("div", { attrs: { id: "pointsChat" } }),
            ]),
            _c(
              "div",
              { staticClass: "bottom-table" },
              [
                _c("h5", { staticClass: "table-title" }, [
                  _vm._v("积分明细表"),
                ]),
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.pointsTableData, stripe: "" },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "create_time", label: "时间" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm
                                        .dayjs(
                                          scope.row["create_timestamp"] * 1000
                                        )
                                        .format("YYYY-MM-DD HH:mm:ss")
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3777733240
                      ),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "points_change",
                        label: "积分变化",
                        width: "170",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "change_reason", label: "说明" },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "points_result", label: "积分余额" },
                    }),
                  ],
                  1
                ),
                _vm.pages.total
                  ? _c("pagination", {
                      staticClass: "pages",
                      attrs: {
                        total: _vm.pages.total,
                        limit: _vm.pages.limit,
                        page: _vm.pages.page,
                        layout: `prev, pager, next, jumper`,
                      },
                      on: {
                        "update:page": function ($event) {
                          return _vm.$set(_vm.pages, "page", $event)
                        },
                        pagination: _vm.handlePage,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.listLoading,
          expression: "listLoading",
        },
      ],
      staticClass: "follow",
    },
    [
      !_vm.listLoading
        ? _c(
            "div",
            { staticClass: "follow-wrap" },
            [
              _vm.pages.total !== 0
                ? _c(
                    "ul",
                    { class: !_vm.type ? "list" : "grid-list" },
                    _vm._l(_vm.followedList, function (item) {
                      return _c(
                        "li",
                        {
                          key: item.camera_id,
                          staticClass: "item csp",
                          on: {
                            click: function ($event) {
                              return _vm.toView(item)
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "thumbnail",
                            attrs: { src: item.latest_image, alt: "缩略图" },
                          }),
                          !_vm.type
                            ? _c("div", { staticClass: "info-wrap" }, [
                                _c("div", { staticClass: "info" }, [
                                  _c("p", { staticClass: "name" }, [
                                    _vm._v(_vm._s(item.camera_name)),
                                  ]),
                                  _c("div", { staticClass: "other-info" }, [
                                    _c("p", { staticClass: "region" }, [
                                      _vm._v(
                                        _vm._s(item.location.replace("｜", " "))
                                      ),
                                    ]),
                                    _c("p", { staticClass: "region" }, [
                                      _vm._v(
                                        "@" + _vm._s(item.owner_user_name)
                                      ),
                                    ]),
                                    _c("p", { staticClass: "region" }, [
                                      _vm._v(
                                        "更新于：" +
                                          _vm._s(item.latest_update_natural)
                                      ),
                                    ]),
                                  ]),
                                ]),
                                _c(
                                  "button",
                                  {
                                    staticClass: "unbind",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.unfollow(item)
                                      },
                                    },
                                  },
                                  [_vm._v("取消关注")]
                                ),
                              ])
                            : _c("div", { staticClass: "info-wrap" }, [
                                _c("div", { staticClass: "info" }, [
                                  _c(
                                    "div",
                                    { staticClass: "flex-align-between" },
                                    [
                                      _c("p", { staticClass: "name" }, [
                                        _vm._v(_vm._s(item.camera_name)),
                                      ]),
                                      _c(
                                        "p",
                                        {
                                          staticClass: "own wes",
                                          attrs: {
                                            title: item.owner_user_name,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "@" + _vm._s(item.owner_user_name)
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "flex-align-between",
                                      staticStyle: { "margin-top": "10px" },
                                    },
                                    [
                                      _c("p", { staticClass: "region" }, [
                                        _vm._v(
                                          _vm._s(
                                            item.location.replace("｜", " ")
                                          )
                                        ),
                                      ]),
                                      _c(
                                        "p",
                                        { staticClass: "region updateTime" },
                                        [
                                          _vm._v(
                                            "更新于:" +
                                              _vm._s(item.latest_update_natural)
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                                _c(
                                  "button",
                                  {
                                    staticClass: "unbind",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.unfollow(item)
                                      },
                                    },
                                  },
                                  [_vm._v("取消关注")]
                                ),
                              ]),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm.pages.total
                ? _c("pagination", {
                    staticClass: "pages",
                    attrs: {
                      total: _vm.pages.total,
                      limit: _vm.pages.limit,
                      page: _vm.pages.page,
                      layout: `prev, pager, next, jumper`,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(_vm.pages, "page", $event)
                      },
                      pagination: _vm.handlePage,
                    },
                  })
                : _vm._e(),
              _vm.pages.total == 0
                ? _c("empty", [
                    _c("p", [_vm._v("抱歉！您的关注列表是空的，")]),
                    _c("p", [
                      _vm._v("请前往 "),
                      _c(
                        "button",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.$router.push("/coummunity/discover")
                            },
                          },
                        },
                        [_vm._v("发现")]
                      ),
                      _vm._v(" 页面寻找感兴趣的视角。"),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
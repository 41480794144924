<template>
	<!-- 视角、影集、关注、积分、成就 入口页面  -->
	<div class="collection">
		<div class="navbar-wrap flex-align-between">
			<div class="nav-bar">
				<div class="nav-item-container" v-for="(item, index) in items" :key="index" @click="selectItem(index)">
					<div class="nav-item">{{ item }}</div>
				</div>
				<div class="underline" :style="{ transform: `translateX(${currentIndex * 100}%)` }"></div>
			</div>
			<div v-if="currentIndex != 3 && currentIndex != 4">
				<button class="refresh-btn" v-if="currentIndex == 1" @click="refreshAlbum">
					<i class="el-icon-refresh-left"></i>
				</button>
				<el-select v-model="value" placeholder="请选择" popper-class="type-select" :popper-append-to-body="false">
					<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
				</el-select>
			</div>
			<button class="howto-btn" v-if="currentIndex == 3">如何获取积分？</button>
			<button class="howto-btn" v-if="currentIndex == 4">什么是成就分？</button>
		</div>
		<div class="content">
			<!-- <component :is="currentComponent" :type="value"></component> -->
			<router-view :type="value" :refresh="isRefresh" @finish="finish"></router-view>
		</div>
	</div>
</template>

<script>
import perspective from "./collection/perspective.vue";
import album from "./collection/album.vue";
import follow from "./collection/follow.vue";
import points from "./collection/points.vue";
import achievements from "./collection/achievements.vue";
export default {
	components: {
		perspective,
		album,
		follow,
		points,
		achievements,
	},
	data() {
		return {
			items: ["视角", "影集", "关注", "积分", "成就"],
			value: 0,
			options: [
				{ value: 0, label: "列表" },
				{ value: 1, label: "卡片" },
			],
			currentIndex: 0,
			isRefresh: false,
		};
	},
	computed: {},
	watch: {
		$route(newVal, oldVal) {
			this.currentIndex = this.items.indexOf(this.$route.meta.name);
		},
	},
	methods: {
		currentComponent() {
			this.value = 0;
			switch (this.items[this.currentIndex]) {
				case "视角":
					return "perspective";
				case "影集":
					return "album";
				case "关注":
					return "follow";
				case "积分":
					return "points";
				case "成就":
					return "achievements";
				default:
					return "perspective";
			}
		},
		selectItem(index) {
			this.currentIndex = index;
			let href = this.currentComponent();
			// console.log(href);
			this.$router.push({ name: href });
		},
		refreshAlbum() {
			this.isRefresh = true;
		},
		finish() {
			this.isRefresh = false;
		},
	},
	mounted() {
		this.currentIndex = this.items.indexOf(this.$route.meta.name);
	},
};
</script>

<style lang="less" scoped>
.collection {
	border-radius: 10px;
	background-color: #ffff;
	overflow: hidden;
	// min-height: 300px;

	.navbar-wrap {
		border-bottom: 1px solid #e2e5ed;
		padding-right: 40px;
	}
	.nav-bar {
		display: flex;
		align-items: center;
		padding-right: 40px;
		position: relative;
	}

	.nav-item-container {
		position: relative;
		text-align: center;
		cursor: pointer;
		transition: color 0.3s;
		line-height: 48px;
		padding: 0 30px;
	}

	.nav-item-container:hover {
		color: #01aded;
	}

	.nav-item {
		position: relative;
	}

	.underline {
		position: absolute;
		bottom: 0px;
		left: 0;
		width: 88px; /* 调整宽度以适应导航项 */
		height: 3px;
		background-color: #01aded;
		transition: transform 0.3s ease-in-out;
	}

	.content {
		// padding: 10px;
		background-color: #fff;
	}

	.data-item {
		margin-right: 10px;
		margin-bottom: 5px;
	}

	.refresh-btn {
		font-size: 16px;
		padding: 10px;
		color: #01aded;
		&:hover {
			color: rgba(1, 173, 237, 0.7);
		}
	}

	.howto-btn {
		color: #01aded;
		&:hover {
			color: rgba(1, 173, 237, 0.7);
		}
	}

	/deep/.el-select {
		width: 80px;

		&:hover .el-input__inner {
			background-color: #e7e7e7;
		}

		.el-input__inner {
			border: none;
			background-color: #efefef;
			color: #333;
			font-size: 14px;
			// padding: 0 10px;
			height: 24px;
			border-radius: 23px;
		}

		.el-select__caret {
			color: #333;
			font-size: 12px;
			line-height: 24px;
			border-radius: 23px;
		}

		.type-select {
			width: 80px;
			min-width: auto !important;
			background-color: #f7f7f7;

			.el-select-dropdown__item.selected {
				background-color: #01aded;
				color: #fff;
			}
		}
	}
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "best-plan" }, [
      _c("img", {
        attrs: { src: require("../../assets/images/common/best.png"), alt: "" },
      }),
      _c("img", {
        attrs: {
          src: require("../../assets/images/common/best-title.svg"),
          alt: "",
        },
      }),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "moudle" }, [
          _c("p", [
            _c("strong", [_vm._v("观天派")]),
            _vm._v(
              "致力于为您提供家用天气实景记录和延时摄影体验。为了进一步提升用户体验，我们特别推出了"
            ),
            _c("strong", [_vm._v("“观天者精选计划”")]),
            _vm._v(
              "。通过精选计划，您不仅可以展示独特的天气视角，还能获得丰厚的积分奖励。以下是精选计划的详细介绍： "
            ),
          ]),
        ]),
        _c("div", { staticClass: "moudle" }, [
          _c("h4", { staticClass: "moudle-title" }, [
            _vm._v("什么是“观天者精选计划”？"),
          ]),
          _c("p", [
            _vm._v(
              "“观天者精选计划”是一个精选优美天气拍摄视角并向全网分享拍摄成果的计划。通过人工的审核，优质的视角和精彩的天气照片将被纳入“精选名录”。入选精选名录的用户每天都将获得积分奖励，享受更多专属福利。"
            ),
          ]),
        ]),
        _c("div", { staticClass: "moudle" }, [
          _c("h4", { staticClass: "moudle-title" }, [
            _vm._v("如何预先申请加入精选计划？"),
          ]),
          _c("p", [
            _vm._v(
              "在购买和安装观天派之前，您可以提前申请加入精选计划。具体步骤如下： "
            ),
          ]),
          _c("ol", { staticClass: "list-ol" }, [
            _c("li", [
              _c("strong", [_vm._v("注册账号：")]),
              _vm._v("登录观天者官方网站，注册账号。"),
            ]),
            _c("li", [
              _c("strong", [_vm._v("选择安装位置并拍摄外景照片：")]),
              _vm._v(
                "选择家中视野开阔的窗户作为安装位置，在计划安装位置拍摄几张外景照片。"
              ),
            ]),
            _c("li", [
              _c("strong", [_vm._v("提交申请：")]),
              _vm._v(
                "点击 观天者精选计划申请 页面，按照说明提交相关资料完成审核。"
              ),
            ]),
            _c("li", [
              _c("strong", [_vm._v("审核通过：")]),
              _vm._v("我们的团队将对您的照片进行质量审核。"),
            ]),
            _c("li", [
              _c("strong", [_vm._v("享受优惠：")]),
              _vm._v(
                "一旦您的申请通过，即可获得购买观天派的折扣券，在购买观天派设备时享受优惠。"
              ),
            ]),
          ]),
          _c("h5", { staticClass: "little-title" }, [
            _vm._v("预先申请精选计划的优势："),
          ]),
          _c("ul", { staticClass: "list-ul" }, [
            _c("li", [
              _c("strong", [_vm._v("专属折扣价：")]),
              _vm._v(
                "提前获得精选资格的用户可享受设备购买优惠，具体优惠价格按申请结果为准。"
              ),
            ]),
            _c("li", [
              _c("strong", [_vm._v("持续积分奖励：")]),
              _vm._v(
                "入选精选名录后，每天正常拍摄都将获得持续的积分奖励，累积更多福利。"
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "moudle" }, [
          _c("h4", { staticClass: "moudle-title" }, [
            _vm._v("安装后如何申请加入精选计划？"),
          ]),
          _c("p", [
            _vm._v(
              "如果您在购买和安装观天派后才决定加入精选计划，也无需担心。您仍然可以通过以下方式轻松申请： "
            ),
          ]),
          _c("ol", { staticClass: "list-ol" }, [
            _c("li", [
              _c("strong", [_vm._v("一键申请：")]),
              _vm._v(
                " 在设备安装并开始拍摄后，点击视角详情里的“一键申请”按钮提交您的申请。"
              ),
            ]),
          ]),
          _c("h5", { staticClass: "little-title" }, [
            _vm._v("后期申请精选计划的优势："),
          ]),
          _c("ul", { staticClass: "list-ul" }, [
            _c("li", [
              _c("strong", [_vm._v("即时奖励：")]),
              _vm._v(
                "审核通过后，您将获得一次性积分奖励，具体数额按申请结果为准。"
              ),
            ]),
            _c("li", [
              _c("strong", [_vm._v("持续收益：")]),
              _vm._v(
                "入选精选名录后，每天正常拍摄都将获得持续的积分奖励，累积更多福利。"
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "moudle" }, [
          _c("h4", { staticClass: "moudle-title" }, [
            _vm._v("精选计划的积分奖励"),
          ]),
          _c("p", [
            _vm._v(
              "无论您是预先申请还是后期申请，入选精选名录后都将享受以下积分奖励："
            ),
          ]),
          _c("ul", { staticClass: "list-ul" }, [
            _c("li", [
              _c("strong", [_vm._v("每日积分：")]),
              _vm._v("每天根据您的照片质量和展示情况，获得相应的积分。"),
            ]),
            _c("li", [
              _c("strong", [_vm._v("兑换福利：")]),
              _vm._v("积累的积分可用于兑换设备配件、服务升级或其他专属福利。"),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "moudle" }, [
          _c("h4", { staticClass: "moudle-title" }, [
            _vm._v("如何提高审核通过率"),
          ]),
          _c("p", { staticStyle: { width: "853px" } }, [
            _vm._v(
              "观天者精选计划的目的是筛选出风景优美或有利于观测天气状况的拍摄视角并向社区分享。因此我们希望您的拍摄视角能尽量满足以下条件： "
            ),
          ]),
          _c("ul", { staticClass: "list-ul" }, [
            _c("li", [_vm._v("视野开阔，以中高层建筑为佳。 ")]),
            _c("li", [
              _vm._v(
                "无遮挡物，没有大片的树木或者建筑物遮挡天空，没有窗户护栏影响视线。"
              ),
            ]),
            _c("li", [
              _vm._v(
                "无强光影响，拍摄方向尽量避免正南方向（易受太阳强光影响，镜头成像质量不稳定）。"
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "moudle" }, [
          _c("h4", { staticClass: "moudle-title" }, [
            _vm._v("立即行动，成为“观天者”精选用户！"),
          ]),
          _c("p", { staticStyle: { width: "853px" } }, [
            _vm._v(
              "无论您是天气摄影爱好者还是热衷于记录天气变化的用户，“观天者精选计划”都将为您带来前所未有的体验和奖励。现在就加入我们，展示您的独特视角，赢取丰厚积分奖励！ "
            ),
          ]),
          _c("p", { staticClass: "more" }, [
            _c("strong", [
              _vm._v("更多详情，请访问我们的观天者精选计划页面。"),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "moudle" }, [
          _c("p", { staticClass: "thanks" }, [
            _vm._v("“感谢您选择"),
            _c("strong", [_vm._v("观天派，")]),
            _vm._v("让我们一起记录每一个美丽的瞬间！”"),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
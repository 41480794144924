var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "achievements",
    },
    [
      !_vm.isLoading
        ? _c("div", { staticClass: "achievements-wrap" }, [
            _c("div", { staticClass: "modules current-level" }, [
              _c("h5", { staticClass: "title level-title" }, [
                _vm._v("当前成就等级"),
              ]),
              _c("div", { staticClass: "level-detail flex-align-between" }, [
                _c("span", { staticClass: "level" }, [
                  _vm._v("Lv" + _vm._s(_vm.currentLevel)),
                ]),
                _c("div", { staticClass: "detial" }, [
                  _c("p", { staticClass: "flex-align-between" }, [
                    _c("span", [_vm._v("成就分：" + _vm._s(_vm.point) + "分")]),
                    _c("span", [
                      _vm._v(
                        "距离下一等级：" +
                          _vm._s(
                            _vm.levelMap[_vm.currentLevel + 1] - _vm.point
                          ) +
                          "分"
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "level-progress" }, [
                    _c("div", {
                      staticClass: "progress-fill",
                      style: { width: `${_vm.progressWidth}%` },
                    }),
                  ]),
                  _c(
                    "div",
                    { staticClass: "level-tag-wrap flex-align-between" },
                    [
                      _c(
                        "div",
                        { staticClass: "level-tag current-level-tag" },
                        [_vm._v("Lv" + _vm._s(_vm.currentLevel))]
                      ),
                      _c("div", { staticClass: "level-tag next-level-tag" }, [
                        _vm._v("Lv" + _vm._s(_vm.currentLevel + 1)),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "modules system" }, [
              _c("h5", { staticClass: "title" }, [_vm._v("成长体系")]),
              _c(
                "ul",
                { staticClass: "flex-layout level-list" },
                _vm._l(_vm.levelMap, function (item, index) {
                  return _c(
                    "li",
                    {
                      key: item,
                      staticClass: "level-list-item",
                      class: _vm.currentLevel == index ? "active" : "",
                    },
                    [
                      _c("img", {
                        staticClass: "crown",
                        attrs: {
                          src: require("@/assets/images/camera/crown.svg"),
                          alt: "",
                        },
                      }),
                      _c("span", { staticClass: "level-point" }, [
                        _vm._v(_vm._s(item)),
                      ]),
                      _c("div", {
                        staticClass: "up-bar",
                        style: { height: `${_vm.calculateHeight(index)}px` },
                      }),
                      _c("span", { staticClass: "level-name" }, [
                        _vm._v("Lv" + _vm._s(index)),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
            _c("div", { staticClass: "modules badges" }, [
              _c("h5", { staticClass: "title" }, [_vm._v("成就徽章")]),
              _c(
                "ul",
                {
                  staticClass: "badges-list",
                  class: { collapsed: _vm.isCollapsed },
                },
                _vm._l(_vm.badgesList, function (item, index) {
                  return _c(
                    "li",
                    { key: index, staticClass: "badges-item" },
                    [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            disabled: item.achieved,
                            effect: "light",
                            content: item.description,
                            placement: "top",
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "badge-image",
                            attrs: { src: item.icon_url, alt: "" },
                          }),
                        ]
                      ),
                      _c("p", { staticClass: "get-time" }, [
                        _vm._v(
                          _vm._s(
                            item.earned_time
                              ? _vm
                                  .dayjs(item.earned_time * 1000)
                                  .format("YYYY.MM.DD")
                              : "未"
                          ) + "获得"
                        ),
                      ]),
                    ],
                    1
                  )
                }),
                0
              ),
              _vm.badgesList.length > 5
                ? _c(
                    "button",
                    {
                      staticClass: "toggle",
                      on: { click: _vm.toggleCollapse },
                    },
                    [
                      _c("i", {
                        class: _vm.isCollapsed
                          ? "el-icon-arrow-down "
                          : "el-icon-arrow-up",
                      }),
                      _vm._v(
                        " " +
                          _vm._s(_vm.isCollapsed ? "查看更多徽章" : "收起") +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
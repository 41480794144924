var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "collection" }, [
    _c("div", { staticClass: "navbar-wrap flex-align-between" }, [
      _c(
        "div",
        { staticClass: "nav-bar" },
        [
          _vm._l(_vm.items, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "nav-item-container",
                on: {
                  click: function ($event) {
                    return _vm.selectItem(index)
                  },
                },
              },
              [_c("div", { staticClass: "nav-item" }, [_vm._v(_vm._s(item))])]
            )
          }),
          _c("div", {
            staticClass: "underline",
            style: { transform: `translateX(${_vm.currentIndex * 100}%)` },
          }),
        ],
        2
      ),
      _vm.currentIndex != 3 && _vm.currentIndex != 4
        ? _c(
            "div",
            [
              _vm.currentIndex == 1
                ? _c(
                    "button",
                    {
                      staticClass: "refresh-btn",
                      on: { click: _vm.refreshAlbum },
                    },
                    [_c("i", { staticClass: "el-icon-refresh-left" })]
                  )
                : _vm._e(),
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择",
                    "popper-class": "type-select",
                    "popper-append-to-body": false,
                  },
                  model: {
                    value: _vm.value,
                    callback: function ($$v) {
                      _vm.value = $$v
                    },
                    expression: "value",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.currentIndex == 3
        ? _c("button", { staticClass: "howto-btn" }, [_vm._v("如何获取积分？")])
        : _vm._e(),
      _vm.currentIndex == 4
        ? _c("button", { staticClass: "howto-btn" }, [_vm._v("什么是成就分？")])
        : _vm._e(),
    ]),
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("router-view", {
          attrs: { type: _vm.value, refresh: _vm.isRefresh },
          on: { finish: _vm.finish },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.listLoading,
          expression: "listLoading",
        },
      ],
      staticClass: "album",
    },
    [
      !_vm.listLoading
        ? _c(
            "div",
            { staticClass: "album-wrap" },
            [
              _vm.pages.total !== 0
                ? _c(
                    "ul",
                    { class: !_vm.type ? "list" : "grid-list" },
                    _vm._l(_vm.albumList, function (item) {
                      return _c(
                        "li",
                        { key: item.video_id, staticClass: "item csp" },
                        [
                          _c("img", {
                            staticClass: "thumbnail",
                            attrs: { src: item.image_url, alt: "缩略图" },
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "info",
                              class: !_vm.type ? "flex-align-between" : "",
                            },
                            [
                              _c("div", { staticClass: "left-info" }, [
                                _c("p", { staticClass: "info-item info-id" }, [
                                  _vm._v(" ID"),
                                  _c("span", { staticClass: "content" }, [
                                    _vm._v(_vm._s(item.video_id)),
                                  ]),
                                ]),
                                _c(
                                  "p",
                                  { staticClass: "info-item info-time" },
                                  [
                                    _vm._v(" 时间"),
                                    _c("span", { staticClass: "content" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm
                                            .dayjs(item.start_time * 1000)
                                            .format("YYYY-MM-DD HH:mm:ss")
                                        ) +
                                          " ~ " +
                                          _vm._s(
                                            _vm
                                              .dayjs(item.end_time * 1000)
                                              .format("YYYY-MM-DD HH:mm:ss")
                                          )
                                      ),
                                    ]),
                                  ]
                                ),
                                _c("p", { staticClass: "info-other" }, [
                                  _c(
                                    "span",
                                    { staticClass: "info-other-item" },
                                    [
                                      _vm._v("地点 "),
                                      _c("i", { staticClass: "content" }, [
                                        _vm._v(_vm._s(item.location)),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "info-other-item" },
                                    [
                                      _vm._v("间隔 "),
                                      _c("i", { staticClass: "content" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.computedTimeInterval(
                                              item.time_interval
                                            )
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "info-other-item" },
                                    [
                                      _vm._v("分辨率 "),
                                      _c("i", { staticClass: "content" }, [
                                        _vm._v(_vm._s(item.resolution)),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "info-other-item" },
                                    [
                                      _vm._v("创建时间 "),
                                      _c("i", { staticClass: "content" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm
                                              .dayjs(
                                                item.task_create_time * 1000
                                              )
                                              .format("YYYY-MM-DD HH:mm:ss")
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]),
                              _c(
                                "div",
                                {
                                  staticClass: "right-info",
                                  class: !_vm.type ? "" : "flex-align-between",
                                },
                                [
                                  _c("p", { staticClass: "info-expiration" }, [
                                    _vm._v(_vm._s(item.status_message)),
                                  ]),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "download-btn",
                                      class: item.completed ? "" : "is-disable",
                                      on: {
                                        click: function ($event) {
                                          return _vm.download(item.download_url)
                                        },
                                      },
                                    },
                                    [_vm._v(" 下载 ")]
                                  ),
                                  _c("p", { staticClass: "info-size" }, [
                                    _vm._v("大小 " + _vm._s(item.video_size)),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm.pages.total
                ? _c("pagination", {
                    staticClass: "pages",
                    attrs: {
                      total: _vm.pages.total,
                      limit: _vm.pages.limit,
                      page: _vm.pages.page,
                      layout: `prev, pager, next, jumper`,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(_vm.pages, "page", $event)
                      },
                      pagination: _vm.handlePage,
                    },
                  })
                : _vm._e(),
              _vm.pages.total == 0
                ? _c("empty", [_c("p", [_vm._v("抱歉！您的影集是空的")])])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
	<div class="points" v-loading="isLoading">
		<!-- 积分 -->
		<div class="points-wrap" v-if="!isLoading">
			<div class="top-chat">
				<p class="current-point">当前积分 &nbsp;&nbsp;{{ point }} <span>近30天积分变化</span></p>
				<div id="pointsChat"></div>
			</div>
			<div class="bottom-table">
				<h5 class="table-title">积分明细表</h5>
				<el-table :data="pointsTableData" style="width: 100%" stripe>
					<el-table-column prop="create_time" label="时间">
						<template slot-scope="scope">
							<span>{{ dayjs(scope.row["create_timestamp"] * 1000).format("YYYY-MM-DD HH:mm:ss") }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="points_change" label="积分变化" width="170"></el-table-column>
					<el-table-column prop="change_reason" label="说明"> </el-table-column>
					<el-table-column prop="points_result" label="积分余额"> </el-table-column>
				</el-table>
				<pagination
					v-if="pages.total"
					class="pages"
					:total="pages.total"
					:limit="pages.limit"
					:page.sync="pages.page"
					:layout="`prev, pager, next, jumper`"
					@pagination="handlePage"></pagination>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			isLoading: true,
			point: 0,
			pages: {
				total: 1,
				page: 1,
				limit: 4,
			},
			pointsTableData: [],
			chatData: [],
		};
	},
	methods: {
		initLineChat() {
			let chatDom = document.getElementById("pointsChat");
			let pointChat = echarts.init(chatDom);
			window.addEventListener("resize", () => {
				pointChat.resize();
			});

			// 处理数据
			let xAxisData = [];
			let seriesData = [];
			// let year = undefined;

			this.chatData.map((item) => {
				// xAxisData.push(this.dayjs(item.point_time * 1000).format("MM.DD"));
				xAxisData.push(item.date);
				seriesData.push(item.final_points);
			});
			// year = this.dayjs(this.chatData[0].point_time * 1000).format("YYYY");

			let option = {
				tooltip: {
					trigger: "axis",
					axisPointer: {
						type: "line",
						lineStyle: {
							color: "rgba(153, 153, 153, 0.6)",
						},
					},
					show: true,
					formatter: `{b}<br/>积分 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{c}`,
					backgroundColor: "rgba(241, 250, 255, 1)",
					padding: [10, 16],
					borderColor: "rgba(241, 250, 255, 1)",
				},
				xAxis: {
					type: "category",
					data: xAxisData,
					axisLine: {
						show: false,
					},
					axisTick: {
						show: false,
					},
					axisLabel: {
						margin: 25,
						color: "rgba(153, 153, 153, 1)",
					},
				},
				yAxis: {
					type: "value",
					axisLabel: {
						color: "rgba(153, 153, 153, 1)",
					},
				},
				series: [
					{
						data: seriesData,
						type: "line",
						symbolSize: 7,
						lineStyle: {
							color: "rgba(1, 173, 237, 1)",
						},
						itemStyle: {
							opacity: 0,
							color: "rgba(1, 173, 237, 1)",
						},
						emphasis: {
							disabled: false,
							label: {
								show: false,
							},
							itemStyle: {
								opacity: 1,
								shadowBlur: 0,
							},
						},
					},
				],
			};
			pointChat.setOption(option);
		},
		// 翻页
		handlePage(data) {
			this.pages.page = data.pageIndex;
			this.getPointsDetails();
		},
		// 获取用户当前积分
		getPoints() {
			Api.User.getPoints()
				.then((resData) => {
					if (resData.status == 200) {
						this.point = resData.data.points;
						this.isLoading = false;
					}
				})
				.catch((error) => {});
		},
		// 获取积分明细表
		getPointsDetails() {
			let params = {
				page: this.pages.page,
				size: this.pages.limit,
			};
			Api.User.getPointsDetails(params)
				.then((resData) => {
					if (resData.status == 200) {
						this.pointsTableData = resData.data.content;
						this.pages.total = resData.data.total_page;
					}
				})
				.catch((error) => {});
		},
		// 获取积分变化趋势
		getPointsHistory() {
			Api.User.getPointsHistory()
				.then((resData) => {
					if (resData.status == 200) {
						this.chatData = resData.data.daily_points;
						console.log(resData.data);
						this.initLineChat();
					}
				})
				.catch((error) => {});
		},
	},
	mounted() {
		this.getPoints();
		this.getPointsDetails();
		this.getPointsHistory();
	},
};
</script>

<style lang="less" scoped>
.points {
	min-height: 300px;
}
.points-wrap {
	padding: 60px 74px 45px;

	.top-chat {
		padding: 0 60px;
		.current-point {
			font-size: 15px;
			font-weight: 600;
			line-height: 24px;
			color: #000;

			& > span {
				display: inline-block;
				padding-left: 24px;
				color: #999;
				font-weight: 500;

				&::before {
					content: "";
					display: inline-block;
					height: 14px;
					width: 1px;
					background-color: #999;
					vertical-align: -2px;
					margin-right: 10px;
				}
			}
		}

		#pointsChat {
			width: 720px;
			height: 350px;
			margin: 0 auto;
		}
	}

	.bottom-table {
		margin-top: 60px;
		.table-title {
			font-size: 15px;
			font-weight: 600;
			color: #000;
			line-height: 24px;
			margin-bottom: 60px;

			&::before,
			&::after {
				content: "";
				display: inline-block;
				height: 1px;
				background: #e2e5ed;
				width: 41%;
				vertical-align: middle;
			}

			&::before {
				margin-right: 35px;
			}
			&::after {
				margin-left: 35px;
			}
		}

		/deep/.el-table {
			th.el-table__cell {
				background-color: #f1faff;
				color: #000;
				border-bottom: 0;
			}
			td.el-table__cell {
				border: 0;
			}
			&.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
				background-color: #f8f8f8;
			}

			&::before {
				display: none;
			}

			.el-table__cell {
				color: #000;
			}

			.el-table_1_column_1 > .cell {
				padding: 0 30px;
			}
		}

		.pages {
			margin-top: 60px;
			text-align: center;
		}
	}
}
</style>

<template>
	<!-- 视角详情 留言模块 -->
	<div class="comment">
		<div v-if="isOwn">
			<div class="tools flex-align-between">
				<div class="left-sort">
					<span>留言显示 </span>
					<el-select v-model="sortValue" placeholder="请选择" popper-class="type-select" :popper-append-to-body="true" @change="sortMode">
						<el-option v-for="item in sortList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
					</el-select>
				</div>
				<div class="right-pagination">
					<pagination
						v-if="pages.total"
						class="pages"
						:total="pages.total"
						:limit="pages.limit"
						:page.sync="pages.page"
						:layout="`prev, pager, next, jumper`"
						@pagination="handlePage"></pagination>
				</div>
			</div>
			<ul class="comment-list">
				<li v-for="item in commentList" :key="item.id" class="comment-item flex-align">
					<div class="left-avatar">
						<img src="@/assets/images/camera/comment-avatar.png" alt="" />
					</div>
					<div class="right-info flex-align">
						<div class="info flex-align" @mouseenter="toggleShinyBtn(item)" @mouseleave="toggleShinyBtn(item)">
							<span class="name wes">{{ item.commenter }}:</span>
							<p class="content wes">{{ item.message_content }}</p>
							<i class="el-icon-success" v-if="item.show"></i>
							<button class="shiny csp" @click="shinyComment(item)" v-if="item.btn">{{ !item.show ? "精选" : "取消精选" }}</button>
						</div>
						<span class="time">{{ dayjs(item.message_time * 1000).format("YYYY-MM-DD HH:mm:ss") }}</span>
					</div>
				</li>
			</ul>
			<div class="reply flex-align">
				<el-input class="reply-input" v-model="replyInputValue"></el-input>
				<button class="submit-comment csp">发表回复</button>
			</div>
		</div>
		<div class="follower" v-else>
			<el-input type="textarea" :rows="6" placeholder="请输入留言" v-model="textarea"></el-input>
			<button class="submit">发表</button>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		controlTime: {
			type: Object,
			default: () => {
				return {};
			},
		},
	},
	data() {
		return {
			pages: {
				total: 10,
				page: 1,
				limit: 3,
			},
			sortValue: 1,
			sortList: [
				{
					value: 1,
					label: "全部显示",
				},
				{
					value: 0,
					label: "智能精选",
				},
				{
					value: 3,
					label: "手动精选",
				},
				{
					value: 2,
					label: "禁止留言",
				},
			],
			commentList: [],
			replyInputValue: "",
			isOwn: false,
			textarea: "",
		};
	},
	watch: {},
	methods: {
		toggleShinyBtn(item) {
			if (this.sortValue == 3) {
				item.btn = !item.btn;
			}
		},
		handlePage(data) {
			this.pages.page = data.pageIndex;
			this.getMessageList();
		},
		// 设置机位留言精选
		shinyComment(item) {
			item.show = !item.show;
			let params = {
				message_id: item.message_id,
				show: item.show,
			};
			Api.Cameras.setShowMessage(params)
				.then((res) => {
					console.log(res);
					if (res.status == 204) {
						this.$message.success("设置成功");
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
		// 获取机位留言列表
		getMessageList() {
			if (JSON.stringify(this.controlTime) == "{}") {
				return;
			}
			let params = {
				camera_id: this.$route.params.id,
				start_time: this.controlTime.start_time,
				end_time: this.controlTime.end_time,
				page: this.pages.page,
				size: this.pages.limit,
			};
			Api.Cameras.getMessageList(params).then((res) => {
				console.log(res);
				this.commentList = res.data.content;
				this.commentList.map((item) => {
					this.$set(item, "btn", false);
				});
				this.pages.total = res.data.total_page;
			});
		},
		// 设置机位留言方式
		sortMode(mode) {
			let params = {
				camera_id: this.$route.params.id,
				message_mode: mode,
			};
			Api.Cameras.setMessageMode(params)
				.then((res) => {
					console.log(res);
					if (res.status == 200) {
						this.sortValue = mode;
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
		// 获取机位留言方式
		getMessageMode() {
			let params = {
				camera_id: this.$route.params.id,
			};
			Api.Cameras.getMessageMode(params)
				.then((res) => {
					// console.log(res);
					if (res.status == 200) {
						this.sortValue = res.data.message_mode;
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
	mounted() {
		if (this.$store.state.user.cameraRole) {
			this.isOwn = this.$store.state.user.cameraRole == "owner" ? true : false;
		}
		this.$nextTick(() => {
			this.getMessageList();
			this.getMessageMode();
		});
	},
};
</script>

<style lang="less" scoped>
.comment {
	padding: 40px 73px 45px;
	/deep/.el-select {
		width: 150px;
		&:hover .el-input__inner {
			background-color: #e7e7e7;
		}

		.el-input__inner {
			border: none;
			background-color: #efefef;
			color: #333;
			font-size: 14px;
			padding: 0 10px;
			height: 24px;
			border-radius: 23px;
			text-align: center;
		}

		.el-select__caret {
			color: #000;
			font-size: 12px;
			line-height: 24px;
			border-radius: 23px;
		}
	}

	.comment-list {
		padding-left: 1px;

		.comment-item:last-child .right-info {
			border-bottom: none;
		}

		.left-avatar {
			display: inline-block;
			border-radius: 50%;
			overflow: hidden;
			width: 38px;
			height: 38px;
			vertical-align: middle;
			margin-right: 19px;

			& > img {
				width: 100%;
			}
		}

		.right-info {
			width: 100%;
			border-bottom: 1px solid #e2e5ed;
			padding: 9px 0;
			line-height: 32px;

			.info {
				flex: 1;
			}
			.el-icon-success {
				margin-left: 16px;
				font-size: 15px;
				color: #27d68f;
			}

			.shiny {
				color: #01aded;
				margin-left: 16px;
				&:hover {
					color: rgba(1, 173, 237, 0.65);
				}
			}

			.content {
				display: inline-block;
				margin-left: 10px;
				max-width: 500px;
			}

			.time {
				color: #c0c0c0;
			}
		}
	}

	.reply {
		margin: 40px auto 0;
		border: 1px solid #c0c0c0;
		padding-right: 15px;
		width: 720px;

		.reply-input {
			flex: 1;

			/deep/ .el-input__inner {
				border: 0;
			}
		}

		.submit-comment {
			flex-shrink: 0;
		}
	}
	.follower {
		text-align: right;
		.submit {
			border-radius: 20px;
			background: #01aded;
			line-height: 40px;
			padding: 0 36px;
			color: #fff;
			margin-top: 24px;

			&:hover {
				background: rgba(1, 173, 237, 0.7);
			}
		}

		/deep/ .el-textarea__inner:focus {
			border-color: #01aded;
		}
	}
}
</style>

<style lang="less">
.type-select {
	background-color: #f7f7f7;

	.el-select-dropdown__item.selected {
		background-color: #01aded;
		color: #fff;
	}

	&.el-select-dropdown.el-popper[x-placement^="bottom-start"] {
		border-radius: 10px;
		box-shadow: 0px 0px 8px 0px rgba(100, 110, 144, 0.15);
		border: 0;
		margin-top: 4px;
		line-height: 36px;
		overflow: hidden;
		text-align: center;

		.el-select-dropdown__list {
			padding: 10px 0;
		}

		.el-select-dropdown__item {
			&:hover {
				background: #01aded;
				color: #fff;
			}
		}
	}
}
</style>

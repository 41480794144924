<template>
	<div id="echarts-earth"></div>
</template>

<script>
// import * as echarts from 'echarts';
// import 'echarts-gl';
export default {
	props: {
		pointList: {
			type: Array,
			default() {
				return [];
			},
		},
	},
	watch: {
		pointList(newValue) {
			this.initGlobe();
		},
	},
	methods: {
		// 创建globe
		initGlobe() {
			let globe = document.getElementById("echarts-earth");
			let myGlobe = echarts.init(globe);

			var canvas = document.createElement("canvas");
			var mapChart = echarts.init(canvas, null, {
				// width: 600,
				// height: 600,
			});

			window.addEventListener("resize", () => {
				myGlobe.resize();
			});

			mapChart.setOption({
				backgroundColor: "#044161",
				series: [
					{
						type: "map",
						map: "world",
						// 绘制完整尺寸的 echarts 实例
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						boundingCoords: [
							[-180, 90],
							[180, -90],
						],
					},
				],
			});

			let option = {
				tooltip: {
					show: true,
					trigger: "item",
					formatter: function (params) {
						// console.log("params",params)
						let currentData = params.data.data.data;
						let content = `${currentData.city}</br>覆盖${currentData.volume}台观天派`;
						return content;
					},
					position: function (pt) {
						return [pt[0] - 70, pt[1] - 80]; // pt 是鼠标的位置，返回 tooltip 的位置
					},
					backgroundColor: "#3E6EFF",
					padding: [10, 15, 10, 17],
					textStyle: {
						color: "rgba(255, 255, 255, 1)",
						lineHeight: 28,
						fontSize: 14,
					},
				},
				globe: {
					baseTexture: require("@/assets/images/echarts/result.png"),
					shading: "color",
					atmosphere: {
						show: true,
					},
					viewControl: {
						autoRotate: false,
						targetCoord: [100, 30.9],
						enableZoom: false,
						enablePan: true,
						zoomSensitivity: 0,
					},
				},
				series: [
					{
						name: "数据",
						type: "scatter3D",
						coordinateSystem: "globe",
						symbolSize: 10,
						itemStyle: {
							color: "#9bc6e5",
							opactiy: 1,
						},
						emphasis: {
							label: {
								show: false,
							},
							itemStyle: {
								color: "#3e6eff",
								opactiy: 1,
							},
						},
						data: this.pointList.map((v) => {
							return {
								name: v.name,
								value: [v.lng, v.lat],
								data: {
									data: v.data,
								},
							};
						}),
					},
				],
			};

			myGlobe.setOption(option);
		},
	},
};
</script>

<style lang="less" scoped>
#echarts-earth {
	width: 600px;
	height: 600px;
	margin: 100px auto 0;

	@media screen and (max-width: 1360px) {
		width: 430px;
		height: 430px;
		margin: 50px auto 0;
	}
}
</style>

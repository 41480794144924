<template>
	<!-- 影集 -->
	<div class="album" v-loading="listLoading">
		<div class="album-wrap" v-if="!listLoading">
			<ul :class="!type ? 'list' : 'grid-list'" v-if="pages.total !== 0">
				<li v-for="item in albumList" :key="item.video_id" class="item csp">
					<img :src="item.image_url" alt="缩略图" class="thumbnail" />
					<div class="info" :class="!type ? 'flex-align-between' : ''">
						<div class="left-info">
							<p class="info-item info-id">
								ID<span class="content">{{ item.video_id }}</span>
							</p>
							<p class="info-item info-time">
								时间<span class="content"
									>{{ dayjs(item.start_time * 1000).format("YYYY-MM-DD HH:mm:ss") }} ~
									{{ dayjs(item.end_time * 1000).format("YYYY-MM-DD HH:mm:ss") }}</span
								>
							</p>
							<p class="info-other">
								<span class="info-other-item"
									>地点 <i class="content">{{ item.location }}</i></span
								>
								<span class="info-other-item"
									>间隔 <i class="content">{{ computedTimeInterval(item.time_interval) }}</i>
								</span>
								<span class="info-other-item"
									>分辨率 <i class="content">{{ item.resolution }}</i></span
								>
								<span class="info-other-item"
									>创建时间 <i class="content">{{ dayjs(item.task_create_time * 1000).format("YYYY-MM-DD HH:mm:ss") }}</i></span
								>
							</p>
						</div>
						<div class="right-info" :class="!type ? '' : 'flex-align-between'">
							<p class="info-expiration">{{ item.status_message }}</p>
							<button class="download-btn" :class="item.completed ? '' : 'is-disable'" @click="download(item.download_url)">
								下载
							</button>
							<p class="info-size">大小 {{ item.video_size }}</p>
						</div>
					</div>
				</li>
			</ul>

			<pagination
				v-if="pages.total"
				class="pages"
				:total="pages.total"
				:limit="pages.limit"
				:page.sync="pages.page"
				:layout="`prev, pager, next, jumper`"
				@pagination="handlePage"></pagination>

			<empty v-if="pages.total == 0">
				<p>抱歉！您的影集是空的</p>
			</empty>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		type: {
			type: Number,
		},
		refresh: {
			type: Boolean,
		},
	},
	data() {
		return {
			pages: {
				total: 0,
				page: 1,
				limit: 6,
			},
			listLoading: true,
			albumList: [],
		};
	},
	computed: {
		viewMode() {
			return this.type === 0 ? "列表" : "卡片";
		},
	},
	mounted() {
		// TODO: 请求：影集接口
		this.getAlbumList();
	},
	watch: {
		refresh(newVal, oldVal) {
			// console.log("refresh", newVal);
			if (newVal) {
				this.getAlbumList();
			}
		},
	},
	methods: {
		computedTimeInterval(time) {
			let labelStr = "";
			if (time <= 60) {
				labelStr = `${time}秒`;
			} else if (time > 60 && time < 60 * 60) {
				labelStr = `${time / 60}分钟`;
			} else if (time >= 60 * 60 && time < 60 * 60 * 24) {
				labelStr = `${time / (60 * 60)}小时`;
			} else if (time >= 60 * 60 * 24) {
				labelStr = `${time / (60 * 60 * 24)}天`;
			}
			return labelStr;
		},
		handlePage(data) {
			this.pages.page = data.pageIndex;
			this.getAlbumList();
		},
		getAlbumList() {
			this.listLoading = true;
			let params = {
				page: this.pages.page,
				// size: this.pages.limit,
			};
			Api.Cameras.getAlbumList(params)
				.then((resData) => {
					// console.log(resData);
					this.albumList = resData.data.content;
					this.pages.total = resData.data.total_count;
					this.listLoading = false;
					this.$emit("finish");
				})
				.catch((error) => {
					console.log("getAlbumList-----Error", error);
				});
		},
		download(url) {
			console.log("下载地址", url);
			window.location.href = url;
		},
	},
};
</script>
<style lang="less" scoped>
.album {
	min-height: 287px;
	// 列表
	.album-wrap {
		min-height: 287px;
		display: flex;
		flex-direction: column;
	}

	.list {
		flex-grow: 1;

		.item {
			display: flex;
			align-items: center;
			border-bottom: 1px solid #e2e5ed;
			padding-left: 31px;
			padding-right: 38px;
			color: #000;

			&:last-child {
				border-bottom: 1px solid transparent;
			}

			&:hover {
				background: #f1faff;

				&:last-child {
					border-bottom: 1px solid #e2e5ed;
				}
			}
		}

		.thumbnail {
			width: 164px;
			height: 93px;
			margin: 16px 31px 16px 0;
			background: aliceblue;
		}

		.info {
			flex: 1;
		}

		.right-info {
			text-align: right;
		}
	}

	// 卡片
	.grid-list {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 40px;
		padding: 45px 60px 5px;

		.item {
			width: 100%;

			&:hover .thumbnail {
				filter: brightness(85%);
			}
		}

		.thumbnail {
			display: block;
			width: 100%;
			height: 243px;
			background: aliceblue;
		}

		.info {
			border-radius: 0px 0px 4px 4px;
			background-color: #f2f2f2;
			position: relative;
		}

		.left-info {
			padding: 14px 30px;
		}

		.right-info {
			border-top: 1px solid #d9d9d9;
			padding: 0 30px;
			line-height: 48px;

			.info-size {
				line-height: 24px;
				position: absolute;
				right: 35px;
				top: 14px;
			}
		}
	}

	// 公共样式
	.list,
	.grid-list {
		.info {
			line-height: 24px;

			.left-info {
				.info-item {
					margin-bottom: 4px;

					.content {
						margin-left: 14px;
					}
				}

				.info-other {
					font-size: 13px;

					.info-other-item {
						color: #9f9f9f;
						margin-right: 22px;

						&:last-child {
							margin-right: 0;
						}

						.content {
							margin-left: 5px;
						}
					}
				}
			}

			.right-info {
				.info-expiration {
					color: #ff2125;
				}

				.download-btn {
					line-height: 24px;
					margin-bottom: 4px;
					font-size: 15px;
					color: #01aded;

					&:hover {
						color: rgba(1, 173, 237, 0.7);
					}

					&.is-disable {
						color: #9f9f9f;
						cursor: not-allowed;
					}
				}
			}
		}
	}

	// 翻页样式
	.pages {
		text-align: right;
		margin-right: 65px;
	}
}
</style>

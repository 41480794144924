<template>
	<div class="best-plan">
		<img src="../../assets/images/common/point.png" alt="" />
		<img class="point-title" src="../../assets/images/common/point-title.svg" alt="" />
		<div class="content">
			<div class="moudle">
				<p>
					欢迎您成为<strong>观天者</strong>社区的一员！为了感谢您的支持与参与，我们特设立了<strong>积分体系</strong>。通过积分，您可以在我们的平台上享受更多专属福利和丰富奖励。以下是积分体系的详细说明：
				</p>
			</div>
			<div class="moudle">
				<h4 class="moudle-title">一、积分简介</h4>
				<p>
					积分是观天者平台内的虚拟代币，您可以通过多种方式获取积分，并用于兑换实体或虚拟商品、购物券，甚至提现。积分的积累与使用将为您带来更加丰富的使用体验和更多的惊喜奖励。
				</p>
			</div>
			<div class="moudle">
				<h4 class="moudle-title">二、积分获取方式</h4>
				<p>您可以通过以下三种方式获取积分：</p>
				<ol class="list-ol">
					<li>
						<strong>观天者精选：</strong>
						通过购买或加盟形式安装“观天派”设备并申请“观天者精选”资格审核，在通过精选资格审核以后，每日正常拍摄并上传天气照片，即可获得固定数量的积分奖励。
					</li>
					<li><strong>悬赏任务：</strong>平台定期发布各类“悬赏任务”，如常规天气分类、灾害天气识别等，完成任务可以获得相应的积分奖励。</li>
					<li><strong>充值兑换：</strong>通过平台提供的充值渠道，使用人民币按兑换比例购买积分包。</li>
					<li>
						<strong>拉新奖励：</strong>用户推荐其他用户通过购买或加盟形式安装“观天派”设备，立刻获取 500
						点积分。若被推荐的用户申请并入选到“观天者精选”，则推荐人可以每天持续获取一定点数的积分，持续 1 年。
					</li>
				</ol>
			</div>
			<div class="moudle">
				<h4 class="moudle-title">三、积分使用方式</h4>
				<p>积累的积分可用于以下多种用途：</p>
				<ol class="list-ol">
					<li>
						<p><strong>素材下载</strong></p>
						<ul class="list-ul">
							<li><strong>视频制作：</strong>使用积分将您的天气照片生成为精彩的视频素材。</li>
							<li><strong>积分消耗：</strong>：每生成一段视频需消耗一定数量的积分，具体消耗量请参考视频生成页面。</li>
						</ul>
					</li>
					<li>
						<p><strong>兑换购物券</strong></p>
						<ul class="list-ul">
							<li><strong>购物卡兑换：</strong>积分可用于兑换京东购物卡、天猫购物卡等多种礼品卡。</li>
							<li>
								<p><strong>兑换流程：</strong></p>
								<ol class="child-list-ol">
									<li>登录账户，进入积分兑换页面。</li>
									<li>选择您希望兑换的购物卡类型和金额。</li>
									<li>确认兑换，兑换成功后购物卡将通过邮件或短信发送至您的账户绑定信息。</li>
								</ol>
							</li>
						</ul>
					</li>
					<li>
						<p><strong>商城兑换商品</strong></p>
						<ul class="list-ul">
							<li>
								<strong>实体与虚拟商品：</strong
								>积分可直接用于兑换商城中上架的各类实体商品（如观天派配件）或虚拟商品（如专属滤镜、特效包）。
							</li>
							<li>
								<p><strong>兑换步骤：</strong></p>
								<ol class="child-list-ol">
									<li>访问商城。</li>
									<li>浏览并选择您感兴趣的商品。</li>
									<li>使用积分完成兑换，商品将根据选择的配送方式寄送至您指定的地址。</li>
								</ol>
							</li>
						</ul>
					</li>
				</ol>
			</div>
			<div class="moudle">
				<h4 class="moudle-title">精选计划的积分奖励</h4>
				<p>无论您是预先申请还是后期申请，入选精选名录后都将享受以下积分奖励：</p>
				<ul class="list-ul">
					<li><strong>每日积分：</strong>每天根据您的照片质量和展示情况，获得相应的积分。</li>
					<li><strong>兑换福利：</strong>积累的积分可用于兑换设备配件、服务升级或其他专属福利。</li>
				</ul>
			</div>
			<div class="moudle">
				<h4 class="moudle-title">四、积分提现规则</h4>
				<p style="width: 853px">为了保障平台的正常运营和用户的权益，积分提现需遵循以下规则：</p>
				<ul class="list-ul">
					<li><strong>提现资格：</strong>用户需要完成个人实名认证和银行卡登记以获得提现资格。</li>
					<li><strong>兑换比例：</strong>1000 积分 = 1元人民币 提现方式：提交 提现申请。</li>
					<li><strong>最低提现额度：</strong>每次提现至少需达到10万点积分（100元人民币）。</li>
					<li><strong>提现时间：</strong>正常情况下将在每月最后5个工作日完成提现。</li>
					<li>
						<strong>税务提示：</strong
						>观天者网站的积分提现在税务上将被视为劳务报酬，每自然月提现超过800元时，平台将按照劳务税率代扣代缴个人所得税，用户可在“个人所得税
						APP” 中查询到代缴记录。
					</li>
					<li>
						<strong>提现流程：</strong><br />
						<p>a. 登录账户，进入积分提现页面。</p>
						<p>b. 选择提现方式和金额，确认信息无误后提交申请。</p>
					</li>
				</ul>
			</div>
			<div class="moudle">
				<h4 class="moudle-title">五、积分有效期</h4>
				<ul class="list-ul">
					<li><strong>有效期限：</strong>积分长期有效。</li>
				</ul>
			</div>
			<div class="moudle">
				<h4 class="moudle-title">六、其他注意事项</h4>
				<ul class="list-ul">
					<li><strong>防止积分滥用：</strong>禁止通过非正常手段获取积分，如发现违规行为，平台有权取消相关积分及账户资格。</li>
					<li><strong>积分调整：</strong>平台保留因系统维护或活动调整对积分规则进行变更的权利，变更将提前在官网公告。</li>
				</ul>
			</div>
			<div class="moudle">
				<h4 class="moudle-title">七、常见问题</h4>
				<h5>Q1 ：如何查看我的积分余额？</h5>
				<p>A1 ：登录您的观天者账户，进入个人主页 -&gt; 积分模块，即可查看当前积分余额及获取记录。</p>
				<h5>Q2 ：积分是否可以转让给他人？</h5>
				<p>A2 ：为了确保积分体系的公平性，积分不可转让、赠予或出售。</p>
				<h5>Q3 ：参与悬赏任务后未能通过审核，积分是否扣除？</h5>
				<p>A3 ：仅在任务通过审核并确认完成后，积分奖励才会发放。未通过审核的任务将不奖励任何积分。</p>
				<h5>Q4 ：充值兑换的积分是否有使用限制？</h5>
				<p>A4 ：充值兑换的积分可用于平台内所有积分可用的用途。</p>
			</div>
			<div class="moudle">
				<h4 class="moudle-title">八、联系我们</h4>
				<p style="width: 853px">如果您对积分体系有任何疑问或建议，欢迎随时联系我们的客服团队：</p>
				<ul class="list-ul">
					<li><strong>客服邮箱：</strong>support@skyviewor.com</li>
				</ul>
			</div>
			<div class="moudle">
				<p class="thanks">“感谢您选择<strong>观天派，</strong>期待与您一同记录美好时光，享受积分带来的丰富奖励！”</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.best-plan {
	padding: 75px 0 100px;

	& > img {
		display: block;
		margin: 0 auto 15px;

		&.point-title {
			margin-top: 50px;
		}
	}

	.content {
		width: 963px;
		margin: 0 auto;
		margin-top: 95px;
	}

	.moudle {
		padding: 35px 0;
		border-bottom: 1px solid #e2e5ed;
		color: #333;
		font-size: 17px;
		font-style: normal;
		line-height: 36px;

		&:last-child {
			border-bottom: 0;
		}

		.moudle-title {
			font-size: 20px;
			margin-bottom: 10px;
			font-weight: 600;
			line-height: 40px;
			color: #181818;
		}

		.little-title {
			font-size: 17px;
			font-weight: 600;
		}

		& > h5 {
			font-weight: 600;
		}

		.list-ol,
		.list-ul {
			margin-left: 1em;
		}

		.list-ol {
			margin-left: 1.3em;
		}

		.list-ol li {
			list-style: auto;
		}

		.list-ul li {
			list-style: disc;
		}

		.child-list-ol li {
            margin-left: 1.3em;
			list-style: upper-roman;
		}

		.thanks {
			color: #007ec4;
		}
	}
}
</style>

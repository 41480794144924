<template>
	<div class="footer">
		<div class="top-wrap flex-layout">
			<div class="left-contact">
				<img class="contact-logo" src="@/assets/images/footer/logo.png" alt="" />
				<h3 class="contact-us">联系我们</h3>
				<!-- <p class="contact-phone">010-0000-0000</p> -->
				<div class="contact-email csp flex-align">
					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
						<circle cx="12" cy="12.02" r="12" fill="#666666" />
						<path
							d="M6.40016 7.02002H17.5998C18.3639 7.02002 18.9903 7.63032 19 8.37822L12.0024 12.2197L5.00647 8.38308C5.01295 7.63194 5.63452 7.02002 6.40016 7.02002ZM5.00647 9.85298L5 16.6456C5 17.4016 5.62967 18.02 6.40016 18.02H17.5998C18.3703 18.02 19 17.4016 19 16.6456V9.84975L12.1675 13.5116C12.0623 13.5682 11.9361 13.5682 11.8325 13.5116L5.00647 9.85298Z"
							fill="white" />
					</svg>
					<a class="email-p">contact@skyviewor.com</a>
				</div>
				<div class="contact-wechat csp flex-align">
					<svg class="svg" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
						<path
							d="M8.3443 8.16103C9.26781 7.19151 10.4383 6.85228 11.8154 6.92723C11.7847 6.78644 11.7835 6.67724 11.7382 6.59116C11.5148 6.16629 11.3392 5.69837 11.0388 5.33477C9.2416 3.15922 5.62377 2.90088 3.52483 4.78652C2.44041 5.76076 1.98877 6.97833 2.29894 8.42184C2.49754 9.34601 3.06515 10.0516 3.8038 10.6112C4.04458 10.7937 4.08358 10.9569 3.98011 11.2196C3.85172 11.5454 3.75494 11.8837 3.64453 12.2166C3.95385 12.1485 4.20381 12.045 4.434 11.9083C4.97378 11.5875 5.5096 11.413 6.15993 11.6112C6.55259 11.7307 6.99604 11.6837 7.47412 11.7152C7.12753 10.3118 7.41048 9.14147 8.3443 8.16103ZM8.75284 5.63132C9.10149 5.62632 9.31179 5.82332 9.31933 6.16179C9.3271 6.51081 9.13078 6.72426 8.79477 6.73213C8.40857 6.74122 8.11684 6.50817 8.11407 6.18844C8.11141 5.88075 8.39152 5.63643 8.75284 5.63132ZM5.44209 6.73201C5.06077 6.7333 4.77784 6.49342 4.78219 6.17252C4.78629 5.86748 5.06597 5.63412 5.43078 5.63123C5.7749 5.62858 6.01362 5.86082 6.00853 6.19344C6.00358 6.51675 5.77832 6.73097 5.44209 6.73201Z"
							fill="white" />
						<path
							d="M14.6841 8.30805C13.2463 6.92336 10.9031 6.70062 9.19601 7.78651C6.9904 9.18948 7.00943 12.0715 9.24472 13.4244C10.3184 14.0743 11.4797 14.2563 12.7003 13.927C12.953 13.8588 13.1573 13.8785 13.3793 14.0161C13.6812 14.2032 14.0027 14.3588 14.3158 14.5279C14.3382 14.5112 14.3606 14.4945 14.383 14.4778C14.2972 14.1786 14.1932 13.8829 14.1349 13.5784C14.1129 13.464 14.1618 13.2989 14.2391 13.2098C14.4383 12.9806 14.6894 12.7964 14.888 12.5667C16.0609 11.2104 15.9775 9.55376 14.6841 8.30805ZM10.3655 9.95123C10.1042 9.95524 9.89282 9.75322 9.90103 9.50742C9.90905 9.26647 10.1001 9.09557 10.3607 9.09627C10.6474 9.09701 10.8824 9.29759 10.874 9.53435C10.8661 9.75489 10.6317 9.94715 10.3655 9.95123ZM12.9702 9.95177C12.7332 9.94819 12.5076 9.74527 12.502 9.53062C12.496 9.30086 12.7251 9.09386 12.9832 9.09582C13.2779 9.09811 13.4912 9.29067 13.4796 9.54406C13.4693 9.77026 13.2374 9.95586 12.9702 9.95177Z"
							fill="white" />
					</svg>
					<a class="wechat-p"
						>企业微信
						<div class="wechat-qrcode"></div>
					</a>
				</div>
			</div>
			<div class="right-nav">
				<div class="nav-list-wrap">
					<h3 class="list-title">帮助 | 文档</h3>
					<ul class="list">
						<li class="list-item" v-for="(item, index) in docList" :key="index">
							<a :href="item.herf" :target="item.target">{{ item.name }}</a>
						</li>
					</ul>
				</div>
				<div class="nav-list-wrap">
					<h3 class="list-title">公司</h3>
					<ul class="list">
						<li class="list-item" v-for="(item, index) in companyList" :key="index">
							<a :href="item.herf">{{ item.name }}</a>
						</li>
					</ul>
				</div>
				<div class="nav-list-wrap">
					<h3 class="list-title">产品</h3>
					<ul class="list">
						<li class="list-item" v-for="(item, index) in productList" :key="index">
							<a :href="item.herf">{{ item.name }}</a>
						</li>
					</ul>
				</div>
				<div class="nav-list-wrap">
					<h3 class="list-title">合作伙伴</h3>
					<ul class="list">
						<li class="list-item" v-for="(item, index) in partnerList" :key="index">
							<a :href="item.herf">{{ item.name }}</a>
						</li>
					</ul>
				</div>
				<div class="nav-list-wrap">
					<h3 class="list-title">常见问题</h3>
					<ul class="list">
						<li class="list-item" v-for="(item, index) in questList" :key="index">
							<a :href="item.herf">{{ item.name }}</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="bottom-wrap">
			<span class="info">&copy;2021 观天者（北京）科技有限公司</span>
			<!-- <span class="info">公司地址：</span> -->
			<!-- <span class="info">公安联网备案xxxxxxxx号</span> -->
			<span class="info"><a class="icp" href="https://beian.miit.gov.cn/#/Integrated/index" target="blank">京ICP备2024080947号-1</a></span>
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			docList: [
				{
					name: "观天派安装",
					herf: "javascript:;",
				},
				{
					name: "积分奖励及使用",
					herf: "/pointGuide",
					target: "blank",
				},
				{
					name: "观天精选计划",
					herf: "/bestPlan",
					target: "blank",
				},
				{
					name: "用户协议",
					herf: "/agreement",
					target: "blank",
				},
				// {
				// 	name: "隐私政策",
				// 	herf: "javascript:;",
				// },
			],
			companyList: [
				{
					name: "关于我们",
					herf: "javascript:;",
				},
				{
					name: "公告",
					herf: "javascript:;",
				},
			],
			productList: [
				{
					name: "观天派",
					herf: "javascript:;",
				},
			],
			partnerList: [
				{
					name: "虚位以待",
					herf: "javascript:;",
				},
			],
			questList: [
				{
					name: "什么是观天派？",
					herf: "javascript:;",
				},
				{
					name: "购买观天派能做什么？",
					herf: "javascript:;",
				},
				{
					name: "观天派如何安装和配置",
					herf: "javascript:;",
				},
				{
					name: "显示更多",
					herf: "javascript:;",
				},
			],
		};
	},
	methods: {},
	mounted() {},
};
</script>

<style lang="less" scoped>
.footer {
	padding: 0 120px;
	height: 295px;
	background-color: #f5f5f5;
	color: #666;
	font-size: 14px;

	a {
		color: #666;
	}

	.top-wrap {
		padding: 48px 0 32px;
		border-bottom: 1px solid #d9d9d9;

		.left-contact {
			line-height: 24px;
			width: 22%;
			min-width: 230px;

			.contact-logo {
				margin-bottom: 12px;
			}

			.contact-us {
				font-size: 16px;
				font-weight: 600;
				margin-bottom: 36px;
			}

			.contact-phone {
				margin-top: 4px;
				color: #181818;
				font-size: 20px;
				font-weight: 600;
				line-height: 32px;
			}

			.contact-email {
				margin-top: 12px;
			}

			.contact-wechat {
				margin-top: 8px;
			}

			.contact-email,
			.contact-wechat {
				.svg {
					width: 24px;
					height: 24px;
					background-color: #666;
					border-radius: 50%;
					padding: 3px;
					box-sizing: border-box;
				}

				.email-p,
				.wechat-p {
					position: relative;
					margin-left: 8px;
					font-size: 14px;
					line-height: 24px;
					text-decoration: none;
					transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
					font-weight: normal;

					&:hover {
						text-decoration: underline;
						color: #333;
						// font-weight: bold;

						.wechat-qrcode {
							display: block;
						}
					}

					&:visited {
						color: #666;
						text-decoration: none;
					}
				}
			}

			.wechat-qrcode {
				box-sizing: content-box;
				width: 107px;
				height: 106px;
				background-image: url("~@/assets/images/footer/qrcode.jpg");
				background-size: 107px 106px;
				border: 6px solid rgb(255, 255, 255);
				border-radius: 3px;
				position: absolute;
				bottom: 40px;
				left: -24px;
				display: none;
				transition: 1s all;

				&::before {
					content: "";
					display: block;
					width: 0px;
					height: 0px;
					border-top: 7px solid rgb(255, 255, 255);
					border-left: 7px solid transparent;
					border-right: 7px solid transparent;
					position: absolute;
					bottom: -13px;
					left: 43px;
				}
			}
		}

		.right-nav {
			flex: 1;
			justify-content: flex-end;
			width: 78%;
			border-left: 1px solid #d9d9d9;
			padding: 2px 0;
			display: grid;
			grid-template-columns: repeat(5, 17%);
			column-gap: 10px;

			.nav-list-wrap {
				margin-right: 10px;
				padding: 0 10px;

				&:first-child {
					margin-left: 10px;
				}
			}

			.list-title {
				margin-bottom: 16px;
				font-size: 16px;
				font-weight: 600;
				line-height: 24px;
				vertical-align: middle;
				text-overflow: ellipsis;
				white-space: nowrap;
			}

			.list-item {
				margin-bottom: 8px;
				line-height: 24px;

				&:last-child {
					margin-bottom: 0;
				}

				a {
					color: #666;
					text-decoration: none;
					transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
					// transition: all 0.3 ease;
					text-overflow: ellipsis;
					white-space: nowrap;
					font-weight: normal;

					&:hover {
						text-decoration: underline;
						color: #333;
						// font-weight: bold;
					}
				}
			}
		}
	}

	.bottom-wrap {
		line-height: 47px;

		.info {
			margin-right: 40px;

			.icp {
				transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
				text-decoration: none;
				font-weight: normal;

				&:hover {
					color: #333;
					text-decoration: underline;
					// font-weight: bold;
				}
			}
		}
	}
}
</style>

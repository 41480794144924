<template>
	<div id="app">
		<headers></headers>
		<router-view v-wechat-title="`${title}`"></router-view>
		<footers>
			<span class="version" v-if="versionHash">version：{{ versionHash }}</span>
		</footers>
	</div>
</template>
<script>
export default { 
	name: "app",
	data() {
		return {
			title: "观天者",
			versionHash: "",
		};
	},
	components: {},
	computed: {},
	methods: {},
	mounted() {},
	updated() {
		// 设置动态标题
		if (this.$route.meta.title) {
			this.title = `${this.$route.meta.title}-观天者`;
		} else {
			this.title = "观天者";
		}
	},
	created() {
		this.versionHash = process.env.VUE_APP_VERSION;
	},
};
</script>
<style lang="less">
@import "less/frontend.less";

// 阿里字体图标设置
.icon,
.iconfont {
	font-family: "iconfont" !important;
	font-size: 16px;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-webkit-text-stroke-width: 0.2px;
	-moz-osx-font-smoothing: grayscale;
}

#app {
	height: 100%;
	min-width: 1200px;
	max-width: 2000px;
	margin: 0 auto;
}
</style>

<template>
	<!-- 关注 -->
	<div class="follow" v-loading="listLoading">
		<div class="follow-wrap" v-if="!listLoading">
			<ul :class="!type ? 'list' : 'grid-list'" v-if="pages.total !== 0">
				<li v-for="item in followedList" :key="item.camera_id" class="item csp" @click="toView(item)">
					<img :src="item.latest_image" alt="缩略图" class="thumbnail" />
					<div class="info-wrap" v-if="!type">
						<div class="info">
							<p class="name">{{ item.camera_name }}</p>
							<div class="other-info">
								<p class="region">{{ item.location.replace("｜", " ") }}</p>
								<p class="region">@{{ item.owner_user_name }}</p>
								<p class="region">更新于：{{ item.latest_update_natural }}</p>
							</div>
						</div>
						<button class="unbind" @click.stop="unfollow(item)">取消关注</button>
					</div>
					<div class="info-wrap" v-else>
						<div class="info">
							<div class="flex-align-between">
								<p class="name">{{ item.camera_name }}</p>
								<p class="own wes" :title="item.owner_user_name">@{{ item.owner_user_name }}</p>
							</div>
							<div class="flex-align-between" style="margin-top: 10px">
								<p class="region">{{ item.location.replace("｜", " ") }}</p>
								<p class="region updateTime">更新于:{{ item.latest_update_natural }}</p>
							</div>
						</div>
						<button class="unbind" @click.stop="unfollow(item)">取消关注</button>
					</div>
				</li>
			</ul>
			<pagination v-if="pages.total" class="pages" :total="pages.total" :limit="pages.limit"
				:page.sync="pages.page" :layout="`prev, pager, next, jumper`" @pagination="handlePage"></pagination>

			<empty v-if="pages.total == 0">
				<p>抱歉！您的关注列表是空的，</p>
				<p>请前往 <button @click="$router.push('/coummunity/discover')">发现</button> 页面寻找感兴趣的视角。</p>
			</empty>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			type: {
				type: Number,
			},
		},
		data() {
			return {
				pages: {
					total: 0,
					page: 1,
					limit: 6,
				},
				listLoading: true,
				followedList: [],
			};
		},
		computed: {
			viewMode() {
				return this.type === 0 ? "列表" : "卡片";
			},
		},
		mounted() {
			this.getFollowedList();
		},

		watch: {},
		methods: {
			handlePage(data) {
				this.pages.page = data.pageIndex;
				this.getFollowedList();
			},
			getFollowedList() {
				this.listLoading = true;
				let params = {
					page: this.pages.page,
				};
				Api.Cameras.getFollowedList(params)
					.then((resData) => {
						// console.log(resData.data);
						this.followedList = resData.data.content;
						this.pages.total = resData.data.total_count;

						this.listLoading = false;
					})
					.catch((error) => {
						console.log("getFollowedList-----Error", error);
					});
			},
			// 取消关注
			unfollow(item) {
				this.$confirm("确定取消关注吗？", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						let params = {
							camera_id: item.camera_id,
						};
						Api.Cameras.unfollow(params)
							.then((resData) => {
								if (resData.status == 204) {
									this.$message({
										type: "success",
										message: "取消关注成功",
									});
									this.getFollowedList();
								}

							})
							.catch((error) => {
								console.log("unfollow-----Error", error);
							});
					})
					.catch(() => {
						this.$message({
							type: "info",
							message: "已取消取消关注",
						});
					});
			},
			// 去视角详情
			toView(item) {
				this.$router.push({
					name: "camera",
					params: {
						id: item.camera_id,
					},
				});
			},
		},
	};
</script>
<style lang="less" scoped>
	.follow {
		min-height: 287px;

		.follow-wrap {
			min-height: 287px;
			display: flex;
			flex-direction: column;
		}

		.list {
			flex-grow: 1;

			.item {
				display: flex;
				align-items: center;
				border-bottom: 1px solid #e2e5ed;
				padding: 0 38px 0 31px;
				color: #000;

				&:last-child {
					border-bottom: 1px solid transparent;
				}

				&:hover {
					background: #f1faff;

					&:last-child {
						border-bottom: 1px solid #e2e5ed;
					}
				}
			}

			.thumbnail {
				width: 164px;
				height: 93px;
				margin: 16px 31px 16px 0;
				flex-shrink: 0;
			}

			.info-wrap {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
			}

			.name {
				display: inline-block;
				min-width: 330px;
			}

			.other-info {
				margin-top: 15px;

				.region {
					display: inline-block;
					color: #666;
					font-size: 12px;
					line-height: 24px;
					margin-right: 40px;
				}
			}

			.unbind {
				line-height: 24px;
				margin-bottom: 4px;
				font-size: 15px;
				color: #01aded;

				&:hover {
					color: rgba(1, 173, 237, 0.7);
				}
			}
		}

		.grid-list {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 20px;
			padding: 45px 60px 5px;

			.item {
				width: 100%;
				position: relative;

				&:hover .thumbnail {
					filter: brightness(85%);
				}
			}

			.thumbnail {
				display: block;
				width: 100%;
				height: 153px;
			}

			.info {
				border-radius: 0px 0px 4px 4px;
				background-color: #f2f2f2;
				padding: 7px 8px 7px 13px;
			}

			.name {
				font-weight: bold;
				color: #000;
				font-size: 15px;
			}

			.region {
				color: #666;
				font-size: 12px;
				line-height: 24px;
				display: inline-block;
			}

			.other-info {
				margin-top: 5px;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			.unbind {
				position: absolute;
				bottom: 80px;
				right: 7px;
				border-radius: 16px;
				border: 1px solid #01aded;
				padding: 0 16px;
				height: 24px;
				line-height: 24px;
				color: #01aded;
				font-size: 13px;
				background-color: #fff;

				&:hover {
					background-color: #01aded;
					color: #fff;
				}
			}
		}

		.pages {
			text-align: right;
			margin-right: 65px;
		}
	}
</style>
<template>
	<!-- 视角详情 天气模块 -->
	<div class="weather flex-layout">
		<div class="regular">
			<h6 class="wetaher-type-title">常规天气类型</h6>
			<ul class="weather-list">
				<li v-for="(item, index) in regularWeather" :key="index" class="weather-item">
					<button class="weather-btn" :class="{ active: currentIndex == index }" @click="selectWeather(index)">{{ item }}</button>
				</li>
				<li class="weahter-input">
					<el-input class="input" placeholder="其他请填写" v-model="regularOtherWeather" @input="entryRegularWeather" />
				</li>
				<li><el-button class="submit-btn" @click="submitCustomWeatherType(0)" :disabled="isPlay">提交</el-button></li>
			</ul>
		</div>
		<div class="special">
			<h6 class="wetaher-type-title">特殊天气类型</h6>
			<ul class="weather-list">
				<li v-for="(item, index) in specialWeather" :key="index" class="weather-item">
					<button class="weather-btn" :class="{ active: currentIndex == index + 7 }" @click="selectWeather(index + 7)">{{ item }}</button>
				</li>
				<li class="weahter-input">
					<el-input class="input" placeholder="其他请填写" v-model="specialOtherWeather" @input="entrySpecialWeather" />
				</li>
				<li><el-button class="submit-btn" @click="submitCustomWeatherType(1)" :disabled="isPlay">提交</el-button></li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		isPlay: {
			type: Boolean,
			required: true,
		},
		frame: {
			type: String,
		},
	},
	data() {
		return {
			regularWeather: ["晴天", "多云", "阴天", "雨天", "雪天", "雾霾"],
			specialWeather: ["台风", "龙卷", "冰雹", "暴雨", "大风", "内涝"],
			regularOtherWeather: "",
			specialOtherWeather: "",
			currentIndex: undefined,
		};
	},
	methods: {
		// 选择天气
		selectWeather(index) {
			this.currentIndex = index;
			this.specialOtherWeather = this.regularOtherWeather = "";
		},
		// 输入常规天气
		entryRegularWeather(val) {
			this.currentIndex = undefined;
			if (this.regularOtherWeather) {
				this.specialOtherWeather = "";
			}
		},
		// 输入特殊天气
		entrySpecialWeather(val) {
			this.currentIndex = undefined;
			if (this.specialOtherWeather) {
				this.regularOtherWeather = "";
			}
		},
		// 提交自定义天气
		submitCustomWeatherType(mode) {
			let weatherType = "";
			if (mode == 0) {
				weatherType = this.regularWeather[this.currentIndex] || this.regularOtherWeather;
			} else {
				weatherType = this.specialWeather[this.currentIndex - 7] || this.specialOtherWeather;
			}
			if (weatherType == "") {
				this.$message.warning("请选择或填写一种天气，再进行提交");
				return;
			}
			let params = {
				image_url: this.frame,
				mode_type: mode,
				weather_type: weatherType,
			};
			// console.log(params);
			Api.Cameras.weatherRecognition(params)
				.then((res) => {
					console.log(res);
					if (res.status == 201) {
						this.$message.success("设置成功");
					}
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
};
</script>

<style lang="less" scoped>
.weather {
	padding: 60px 83px 80px;

	.regular,
	.special {
		width: 50%;
		color: #333;

		.wetaher-type-title {
			font-size: 13px;
			margin-bottom: 14px;
		}

		.weather-list {
			display: grid;
			grid-template-columns: repeat(5, 1fr);
			grid-gap: 14px 10px;
		}

		.weather-item {
			width: 60px;
			.weather-btn {
				border-radius: 4px;
				border: 1px solid #c0c0c0;
				line-height: 24px;
				height: 24px;
				padding: 0 14px;
				cursor: pointer;

				&:hover {
					border: 1px solid #01aded;
				}

				&.active {
					border: 1px solid #01aded;
					color: #01aded;
				}
			}
		}

		.weahter-input {
			grid-column: span 2;
			.input {
				width: 100%;
			}

			/deep/ .el-input__inner {
				height: 26px;
				line-height: 20px;
				padding: 0 5px 0;
				border: 1px solid #c0c0c0;
				border-radius: 0;
				font-size: 13px;

				/* browsers */
				&::placeholder {
					color: #c0c0c0;
					text-align: center;
				}
				/* WebKit browsers */
				&::-webkit-input-placeholder {
					color: #c0c0c0;
					text-align: center;
				}
				/* Mozilla Firefox 19+ */
				&::-moz-placeholder {
					color: #c0c0c0;
					text-align: center;
				}
				/* Internet Explorer 10+ */
				&:-ms-input-placeholder {
					color: #c0c0c0;
					text-align: center;
				}
			}
		}

		.submit-btn {
			border-radius: 4px;
			line-height: 24px;
			height: 26px;
			padding: 0 14px;
			color: #fff;
			border: 1px solid #01aded;
			background-color: #01aded;
			&:hover,
			&:active {
				background: #4ec5f1;
				border: 1px solid #4ec5f1;
			}

			&.is-disabled {
				color: #c0c4cc;
				cursor: not-allowed;
				background-image: none;
				background-color: #fff;
				border-color: #ebeef5;
			}
		}
	}

	.special {
		border-left: 1px solid #e2e5ed;
		padding-left: 70px;
	}

	.regular {
		padding-right: 70px;
	}
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "empty" }, [
    _c("div", { staticClass: "empty-wrap" }, [
      _c("img", {
        staticClass: "empty-image",
        attrs: { src: require("@/assets/images/common/empty.svg"), alt: "" },
      }),
      _c("div", { staticClass: "empty-text" }, [_vm._t("default")], 2),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
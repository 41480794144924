<template>
	<!-- 社区主页 -->
	<div class="coummunity" ref="coummunity">
		<div class="main flex-layout">
			<div class="sidebar" ref="sidebar">
				<div>
					<div class="sidebar-title">社区</div>
					<ul class="sidebar-list">
						<li
							class="sidebar-list-item csp"
							:class="{ active: currentName == item.name }"
							v-for="(item, index) in list"
							:key="index"
							@click="swichItem(item)">
							{{ item.name }}
						</li>
					</ul>
				</div>
				<div class="sidebar-ad">广告位</div>
			</div>
			<router-view class="coummunity-view"></router-view>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			currentName: "发现",
			list: [
				{ name: "发现", href: "/coummunity/discover" },
				// { name: "悬赏", href: "" },
				{ name: "我的", href: "/coummunity/my/perspective" },
			],
		};
	},
	watch: {
		$route(newVal, oldVal) {
			this.init();
		},
	},
	methods: {
		swichItem(item) {
			if (item.href) {
				this.currentName = item.name;
				this.$router.push(item.href);
			} else {
				this.$message.info("暂未开放,敬请期待");
			}
		},
		init() {
			if (!this.$route.meta.title) {
				return;
			}
			this.list.map((item) => {
				if (this.$route.meta.title.includes(item.name)) {
					this.currentName = item.name;
				}
			});
		},
	},
	mounted() {
		this.init();
	},
};
</script>

<style lang="less" scoped>
.coummunity {
	background: #f2f2f2;

	.main {
		margin: 0 auto;
		width: 1200px;
		height: 100%;
		padding-top: 13px;
		padding-bottom: 30px;
		box-sizing: border-box;
		position: relative;
	}

	.sidebar {
		margin-right: 14px;
		border-radius: 10px;
		box-shadow: 0px 0px 8px 0px rgba(100, 110, 144, 0.1);
		width: 200px;
		height: 800px;
		background-color: #fff;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex-shrink: 0;
		position: -webkit-sticky; /* Safari */
		position: sticky;
		top: 13px;
		left: 0;
		z-index: 999;

		@media screen and (max-width: 1220px) {
			position: relative;
			top: 0;
		}

		.sidebar-title {
			font-size: 20px;
			font-weight: 600;
			line-height: 24px;
			padding: 36px 24px;
			background-color: #fafafa;
		}

		.sidebar-list {
			.sidebar-list-item {
				font-size: 16px;
				font-weight: 600;
				line-height: 52px;
				padding: 0px 24px;
				position: relative;
				&::before {
					content: "";
					display: inline-block;
					position: absolute;
					left: 0;
					width: 3px;
					height: 52px;
					background-color: #01aded;
					display: none;
				}

				&:hover,
				&.active {
					background-color: #f1faff;
					&::before {
						display: block;
					}
				}
			}
		}

		.sidebar-ad {
			height: 300px;
			background-color: #f1faff;
			text-align: center;
			line-height: 300px;
		}
	}
}
</style>

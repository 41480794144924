<template>
	<div class="achievements" v-loading="isLoading">
		<!-- 成就 -->
		<div class="achievements-wrap" v-if="!isLoading">
			<div class="modules current-level">
				<h5 class="title level-title">当前成就等级</h5>
				<div class="level-detail flex-align-between">
					<span class="level">Lv{{ currentLevel }}</span>
					<div class="detial">
						<p class="flex-align-between">
							<span>成就分：{{ point }}分</span>
							<span>距离下一等级：{{ levelMap[currentLevel + 1] - point }}分</span>
						</p>
						<div class="level-progress">
							<div class="progress-fill" :style="{ width: `${progressWidth}%` }"></div>
						</div>
						<div class="level-tag-wrap flex-align-between">
							<div class="level-tag current-level-tag">Lv{{ currentLevel }}</div>
							<div class="level-tag next-level-tag">Lv{{ currentLevel + 1 }}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="modules system">
				<h5 class="title">成长体系</h5>
				<ul class="flex-layout level-list">
					<li v-for="(item, index) in levelMap" :key="item" class="level-list-item" :class="currentLevel == index ? 'active' : ''">
						<img src="@/assets/images/camera/crown.svg" alt="" class="crown" />
						<span class="level-point">{{ item }}</span>
						<div class="up-bar" :style="{ height: `${calculateHeight(index)}px` }"></div>
						<span class="level-name">Lv{{ index }}</span>
					</li>
				</ul>
			</div>
			<div class="modules badges">
				<h5 class="title">成就徽章</h5>
				<ul class="badges-list" :class="{ collapsed: isCollapsed }">
					<li v-for="(item, index) in badgesList" :key="index" class="badges-item">
						<el-tooltip :disabled="item.achieved" class="item" effect="light" :content="item.description" placement="top">
							<img :src="item.icon_url" alt="" class="badge-image" />
						</el-tooltip>
						<p class="get-time">{{ item.earned_time ? dayjs(item.earned_time * 1000).format("YYYY.MM.DD") : "未" }}获得</p>
					</li>
				</ul>
				<button class="toggle" @click="toggleCollapse" v-if="badgesList.length > 5">
					<i :class="isCollapsed ? 'el-icon-arrow-down ' : 'el-icon-arrow-up'"></i>
					{{ isCollapsed ? "查看更多徽章" : "收起" }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			isLoading: true,
			point: 0,
			pages: {
				total: 1,
				page: 1,
				limit: 4,
			},
			isCollapsed: true,
			currentLevel: 0,
			levelMap: {
				0: 10,
				1: 27,
				2: 73,
				3: 200,
				4: 545,
				5: 1484,
				6: 4034,
				7: 10966,
				8: 29809,
				9: 81030,
				10: 220264,
			},
			progressWidth: 0,
			badgesList: [],
		};
	},
	methods: {
		// 计算成长体系 等级高度
		calculateHeight(index) {
			const baseHeight = 5;
			const scaleFactor = 1.42; // 调整这个值可以改变增长速度
			return baseHeight * Math.pow(scaleFactor, index);
		},
		// 下拉展示更多徽章
		toggleCollapse() {
			this.isCollapsed = !this.isCollapsed;
		},
		// 获取成就分
		getAchievement() {
			Api.User.getAchievement()
				.then((resData) => {
					if (resData.status == 200) {
						// console.log(resData);
						this.point = resData.data.achievement_points;
						this.currentLevel = resData.data.achievement_level;
						this.progressWidth = Math.ceil((this.point / this.levelMap[this.currentLevel + 1]) * 100);
						this.isLoading = false;
						// console.log(this.progressWidth);
					}
				})
				.catch((error) => {});
		},
		// 获取徽章
		getBadges() {
			Api.User.getBadges()
				.then((resData) => {
					if (resData.status == 200) {
						this.badgesList = resData.data.sort((a, b) => Number(b.achieved) - Number(a.achieved));
					}
				})
				.catch((error) => {});
		},
	},
	mounted() {
		this.getAchievement();
		this.getBadges();
	},
};
</script>

<style lang="less" scoped>
.achievements {
	min-height: 300px;
}

.achievements-wrap {
	padding: 60px 74px 45px;

	.modules {
		margin-bottom: 60px;
		.title {
			font-size: 15px;
			font-weight: 600;
			color: #000;
			line-height: 24px;
			margin-bottom: 60px;

			&.level-title {
				&::before {
					margin-right: 27px;
				}
				&::after {
					margin-left: 27px;
				}
			}

			&::before,
			&::after {
				content: "";
				display: inline-block;
				height: 1px;
				background: #e2e5ed;
				width: 41%;
				vertical-align: middle;
			}

			&::before {
				margin-right: 42px;
			}
			&::after {
				margin-left: 42px;
			}
		}
	}

	.level-detail {
		box-sizing: border-box;
		padding: 0 63px;
		margin: 0 auto;
		width: 720px;
		height: 160px;
		border-radius: 12px;
		background: linear-gradient(132deg, #f6feff 29.7%, #dff4ff 69.41%, #f6feff 98.88%);

		.level {
			display: inline-block;
			font-size: 60px;
			font-weight: 600;
			background: linear-gradient(139deg, #a9e2ff -12.93%, #009bdd 130.06%);
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}

		.detial {
			margin-left: 45px;
			flex: 1;
			line-height: 24px;

			.level-progress {
				margin: 6px 0 14px;
				border-radius: 22px;
				background: #d9d9d9;
				height: 6px;
				width: 100%;
			}

			.progress-fill {
				border-radius: 22px;
				background: #01aded;
				height: 6px;
			}

			.level-tag {
				border-radius: 25px;
				background: #ebe1c0;
				line-height: 17px;
				color: #000;
				font-size: 13px;
				padding: 0 6px;
			}
		}
	}

	.level-list {
		justify-content: space-between;
		align-items: flex-end;
		padding: 0 100px;
		.level-list-item {
			text-align: center;

			&.active {
				.crown {
					visibility: visible;
				}
				.up-bar {
					background-color: #01aded;
				}
			}
		}
		.crown {
			display: block;
			margin: 0 auto;
			visibility: hidden;
		}

		.level-point {
			font-size: 13px;
			color: #000;
			line-height: 24px;
		}

		.up-bar {
			// height: 150px;
			width: 10px;
			border-radius: 10px 10px 0px 0px;
			background: #d9d9d9;
			margin: 4px auto 16px;
		}

		.level-name {
			border-radius: 25px;
			background: #ebe1c0;
			line-height: 17px;
			color: #000;
			font-size: 13px;
			padding: 0 6px;
		}
	}

	.badges {
		margin-bottom: 0;
	}

	.badges-list {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		grid-gap: 30px;
		text-align: center;
		padding: 0 76px;
		height: 500px;
		overflow: hidden;
		transition: height 0.8s ease;

		&.collapsed {
			height: 130px;
		}

		.badge-image {
			width: 111px;
			height: 87px;
		}

		.get-time {
			margin-top: 13px;
			font-size: 13px;
			line-height: 24px;
		}
	}

	.toggle {
		display: block;
		margin: 34px auto 0;
		color: #7c7c7c;

		&:hover {
			color: #01aded;
		}
	}
}
</style>

<template>
	<!-- 绑定视角 -->
	<div class="binding">
		<div class="back" ref="back">
			<el-button class="back-btn" @click="$router.go(-1)">返回</el-button>
		</div>
		<div class="form-wrap">
			<el-form :model="bindingForm" :rules="bindingFormRules" ref="bindingForm" hide-required-asterisk inline label-position="top">
				<el-form-item class="sn_code" prop="SNcode" label="S/N 码">
					<el-input style="width: 350px" v-model="bindingForm.SNcode" placeholder="请输入 SN 码进行观天派视角绑定"></el-input>
					<i :class="snCorrect ? 'el-icon-success' : 'el-icon-warning'" v-if="verifyIcon"></i>
				</el-form-item>
				<div class="sketch">
					<p class="sketch-tips">如何查找设备的 SN 码</p>
					<img class="sketch-image" src="@/assets/images/common/SNcode.jpg" />
				</div>
				<el-form-item class="cameraName" prop="cameraName" label="视角名称">
					<el-input style="width: 350px" v-model="bindingForm.cameraName" placeholder="请输入视角名称" maxlength="15" show-word-limit>
					</el-input>
				</el-form-item>
				<el-form-item class="city" prop="city" label="所在地区">
					<el-cascader
						placeholder="未设置"
						v-model="bindingForm.city"
						:options="cityOptions"
						popperClass="city-choose"
						:append-to-body="false"
						style="width: 350px"
						:props="{ value: 'label', emitPath: true }"
						@change="cityChange"></el-cascader>
				</el-form-item>
			</el-form>
			<button class="submit-binding" @click="submitForm">确定</button>
		</div>

		<!-- 邮箱验证对话框 -->
		<el-dialog
			:title="`视角 ${bindingForm.cameraName} 已绑定成功`"
			:visible.sync="visibleSuccessDialog"
			:append-to-body="true"
			:show-close="true"
			width="450px"
			:destroy-on-close="true"
			:close-on-click-modal="false"
			custom-class="successDialog">
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="finishBinding">继续绑定</el-button>
				<button class="back csp" @click="$router.go(-1)">返回视角列表</button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import citysJson from "/static/ChinaCitysAll.json";
export default {
	data() {
		var checkSNcode = (rule, value, callback) => {
			if (!value) {
				this.verifyIcon = false;
				return callback(new Error("请填写 S/N 码"));
			} else {
				let params = {
					sn: value,
				};
				Api.Cameras.verifySNcode(params)
					.then((resData) => {
						this.verifyIcon = true;
						if (resData.data.sn_correct) {
							this.snCorrect = resData.data.sn_correct;
							return callback();
						} else {
							this.snCorrect = resData.data.sn_correct;
							return callback(new Error(resData.data.message));
						}
					})
					.catch((error) => {
						console.log(error);
						return callback();
					});
			}
		};
		var checkCameraName = (rule, value, callback) => {
			if (!value) {
				return callback(new Error("请填写视角名称"));
			} else {
				return callback();
			}
		};
		var checkCity = (rule, value, callback) => {
			if (!value) {
				return callback(new Error("请选择所在地区"));
			} else {
				return callback();
			}
		};
		return {
			visibleSuccessDialog: false,
			cityOptions: citysJson,
			verifyIcon: false,
			snCorrect: undefined,
			bindingForm: {},
			bindingFormRules: {
				SNcode: [
					{
						required: true,
						trigger: "blur",
						validator: checkSNcode,
					},
				],
				cameraName: [
					{
						required: true,
						trigger: "blur",
						validator: checkCameraName,
					},
				],
				city: [
					{
						required: true,
						trigger: "change",
						validator: checkCity,
					},
				],
			},
		};
	},
	methods: {
		// 重置表单
		resetForm(formName) {
			this.$refs[formName].resetFields();
		},
		// 更改所在区域
		cityChange(value) {
			console.log(value);
		},
		// 提交绑定
		submitForm() {
			this.$refs.bindingForm.validate((valid) => {
				if (valid) {
					let params = {
						sn: this.bindingForm.SNcode,
						camera_name: this.bindingForm.cameraName,
						location: {
							province: this.bindingForm.city[0],
							city: this.bindingForm.city[1],
							district: this.bindingForm.city[2],
						},
					};
					console.log(params);
					Api.Cameras.cameraBind(params)
						.then((resData) => {
							if (resData.status == 204) {
								this.visibleSuccessDialog = true;
							}
						})
						.catch((error) => {
							console.log(error);
						});
				} else {
					this.$message.error("请正确填写数据");
				}
			});
		},
		// 继续绑定
		finishBinding() {
			this.visibleSuccessDialog = false;
			this.verifyIcon = false;
			this.resetForm("bindingForm");
		},
	},
};
</script>

<style lang="less" scoped>
.binding {
	border-radius: 10px;
	background: #fff;
	width: 100%;
	padding: 26px 0 60px 30px;

	.back-btn {
		border-radius: 16px;
		border: 1px solid #01aded;
		padding: 0 16px;
		line-height: 24px;
		color: #01aded;

		&:hover {
			background-color: #01aded;
			color: #fff;
		}
	}

	/deep/ .form-wrap {
		padding: 50px 118px;

		.sn_code {
			& > .el-form-item__label {
				padding: 0 20px 0 0;
				font-weight: 600;
				color: #333;
			}
			& > .el-form-item__content {
				display: inline-block;
			}

			.el-icon-success {
				margin-left: 15px;
				font-size: 16px;
				color: #27d68f;
			}

			.el-icon-warning {
				margin-left: 16px;
				font-size: 16px;
				color: #ff4545;
			}
		}

		.cameraName {
			margin-right: 50px;
		}

		.city {
			margin-right: 0px;
		}

		.cameraName,
		.city {
			& > .el-form-item__label {
				font-weight: 600;
				padding: 0 0 5px;
				color: #333;
			}
		}

		.el-input__inner {
			border-radius: 10px;
			line-height: 50px;
			height: 50px;
			border: 1px solid #d9d9d9;
			padding: 0 10px;

			&::placeholder {
				color: #9f9f9f;
			}

			&:focus {
				border-color: #01aded;
			}
		}

		.el-form-item.is-error .el-input__inner,
		.el-form-item.is-error .el-input__inner:focus,
		.el-form-item.is-error .el-textarea__inner,
		.el-form-item.is-error .el-textarea__inner:focus,
		.el-message-box__input input.invalid,
		.el-message-box__input input.invalid:focus {
			border-color: #f56c6c;
		}
	}

	.sketch {
		margin: 14px 0 34px;
		.sketch-tips {
			margin-bottom: 16px;
		}
		.sketch-image {
			width: 750px;
			height: 252px;
			background: #f2f2f2;
		}
	}

	.submit-binding {
		display: block;
		margin: 58px auto 0;
		color: #fff;
		border-radius: 20px;
		background-color: #01aded;
		padding: 0 36px;
		line-height: 40px;
		cursor: pointer;
		&:hover {
			background-color: rgba(1, 173, 237, 0.7);
		}
	}
}
</style>

<style lang="less">
.city-choose {
	border-radius: 10px;
	box-shadow: 0px 0px 8px 0px rgba(100, 110, 144, 0.15);
	border: 0;
	margin-top: 4px;
	line-height: 36px;
	overflow: hidden;
	text-align: center;

	.el-cascader-menu__list {
		padding: 10px 0;
	}

	.el-cascader-node {
		&.in-active-path {
			background: #01aded;
			color: #fff;
		}

		&:hover {
			background: #01aded;
			color: #fff;
		}
	}

	.el-cascader-menu {
		border: 0;

		.el-cascader-menu__wrap {
			margin-right: 0 !important;
			padding-right: 5px;

			/*修改滚动条样式*/
			&::-webkit-scrollbar {
				width: 5px;
			}
		}

		.el-cascader-node__postfix {
			display: none;
		}

		.el-icon-check::before {
			display: none;
		}

		.el-cascader-node.is-active {
			background: #01aded;
			color: #fff;
		}
	}
}

// 修改手机号弹窗
@primary-color: #3e6eff;
@text-color: #333;

.successDialog {
	border-radius: 0;
	box-shadow: 0px 0px 12px 0px rgba(100, 110, 144, 0.15);
	padding: 79px 60px 28px;

	.el-dialog__header {
		padding: 0;
		text-align: center;
	}

	.el-dialog__title {
		font-size: 16px;
		font-weight: 600;
		line-height: 32px;
		color: #000;
	}

	.el-dialog__body {
		padding: 35px 0px 1px;
	}

	.el-dialog__footer {
		padding: 0;

		.el-button {
			display: block;
			width: auto;
			margin: 0 auto;
			border-radius: 10px;
			border: 0;
			padding: 5px 32px;
			line-height: 30px;
			font-size: 16px;
			font-weight: 600;
			background-color: #01aded;

			&:active,
			&:hover {
				background-color: #4ec5f1;
			}
		}

		.el-button.is-disabled {
			background: #e0e0e0;
		}

		.back {
			margin-top: 10px;
			display: block;
			text-align: center;
			line-height: 30px;
			padding: 10px;
			width: 100%;
			color: #999;
			font-size: 15px;
			font-weight: 600;
			&:hover {
				color: rgba(153, 153, 153, 0.65);
			}
		}
	}
}
</style>

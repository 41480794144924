<template>
	<!-- 产品预定 -->
	<div class="reservation">
		<div class="reserve-wrap">
			<div style="border-bottom: 1px solid #e2e5ed">
				<img src="@/assets/images/common/reservation.png" alt="" class="reserve-img" />
				<img src="@/assets/images/common/reserve-title.svg" alt="" class="reserve-title-img" />
			</div>
			<el-form :model="form" :rules="rules" ref="reserveForm" label-width="100px" class="reserve-form">
				<el-form-item label="您的称呼" prop="name" required>
					<el-input v-model="form.name" autocomplete="off" maxlength="18" show-word-limit placeholder="请输入您的称呼"></el-input>
				</el-form-item>
				<el-form-item label="您的邮箱" prop="email" required>
					<el-input v-model="form.email" autocomplete="off" placeholder="请输入您的邮箱"></el-input>
				</el-form-item>
				<el-form-item label="手机号" prop="phone">
					<el-input v-model.number="form.phone" placeholder="请输入您的手机号"></el-input>
				</el-form-item>
				<el-form-item label="您的行业" prop="industry" required>
					<el-select v-model="form.industry" placeholder="请选择您的所在行业" :popper-append-to-body="false" style="width: 100%">
						<el-option v-for="item in industryOptions" :key="item.value" :label="item.label" :value="item.label"> </el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="您逾期的观天派价格" prop="price" required>
					<el-radio-group v-model="form.price">
						<el-radio :label="0">100元以内</el-radio>
						<el-radio :label="1">200元以内</el-radio>
						<el-radio :label="2">500元以内</el-radio>
						<el-radio :label="3">500元以上</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item>
					<el-button class="submit-btn" type="primary" @click.prevent="submitForm()" :loading="isLoading">{{
						isLoginLoading ? "提交中" : "提交"
					}}</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
import captcha from "./login/captcha";
export default {
	data() {
		// 验证手机号 
		var checkPhone = (rule, value, callback) => {
			var phoneRegex = /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/;
			if (!value) {
				return callback();
			} else if (!phoneRegex.test(value)) {
				this.sendCodeBtn = true;
				return callback(new Error("手机号格式异常，请检查并重新输入"));
			} else {
				return callback();
			}
		};
		// 验证邮箱格式 
		var checkEmail = (rule, value, callback) => {
			var emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
			if (!value) {
				return callback(new Error("请输入邮箱"));
			} else if (!emailRegex.test(value)) {
				return callback(new Error("请输入正确的邮箱格式"));
			} else if (value && emailRegex.test(value)) {
				let params = {
					email: value,
				};
				Api.Home.checkBookingEmail(params)
					.then((resData) => {
						console.log("exists邮箱", resData.data.existing);
						if (resData.data.existing) {
							return callback(new Error("该邮箱已提交预定，请勿重复提交，注意查收邮件"));
						} else {
							return callback();
						}
					})
					.catch((error) => {
						console.log(error);
					});
			} else {
				return callback();
			}
		};
		return {
			isLoading: false,
			form: {},
			rules: {
				name: [
					{
						required: true,
						trigger: "blur",
						message: "请输入您的称呼",
					},
				],
				email: [
					{
						required: true,
						trigger: "blur",
						validator: checkEmail,
					},
				],
				phone: [
					{
						required: false,
						trigger: "blur",
						validator: checkPhone,
					},
				],
				industry: [
					{
						required: true,
						trigger: "blur",
						message: "请选择您的行业",
					},
				],
				price: [
					{
						required: true,
						trigger: "blur",
						message: "请选择您逾期的观天派价格",
					},
				],
			},
			industryOptions: [
				{
					value: "0",
					label: "气象服务",
				},
				{
					value: "1",
					label: "科学研究与技术服务",
				},
				{
					value: "2",
					label: "制造业",
				},
				{
					value: "3",
					label: "信息技术和互联网",
				},
				{
					value: "4",
					label: "金融业",
				},
				{
					value: "5",
					label: "房地产与建筑业",
				},
				{
					value: "6",
					label: "教育与培训",
				},
				{
					value: "7",
					label: "医疗与健康",
				},
				{
					value: "8",
					label: "文化娱乐与传媒",
				},
				{
					value: "9",
					label: "零售与批发",
				},
				{
					value: "10",
					label: "交通与物流",
				},
				{
					value: "11",
					label: "能源与环境",
				},
				{
					value: "12",
					label: "法律与咨询",
				},
				{
					value: "13",
					label: "政府与公共事业",
				},
				{
					value: "14",
					label: "农业与自然资源",
				},
				{
					value: "15",
					label: "其他",
				},
			],
		};
	},
	methods: {
		submitForm() {
			this.isLoading = true;
			this.$refs.reserveForm.validate((valid) => {
				if (valid) {
					console.log(this.form);
					this.requestSubmit();
				} else {
					console.log("false");
					this.isLoading = false;
					return false;
				}
			});
		},
		async requestSubmit() {
			let res = await captcha.captchaTrigger();
			// console.log("callback", res);
			if (res.ret === 0) {
				let params = {
					email: this.form.email,
					randstr: res.randstr,
					ticket: res.ticket,
					name: this.form.name,
					phone_num: String(this.form.phone),
					industry: this.form.industry,
					expect_price: this.form.price,
				};
				Api.Home.booking(params)
					.then((resData) => {
						if (resData.status == 201) {
							this.isLoading = false;
							this.$message.success("预定成功,请注意查收邮件");
							this.$router.push("/home");
						}
					})
					.catch((error) => {
						console.log(error);
						this.isLoading = false;
						this.$message.error("预定失败，请联系管理员");
					});
			} else {
				this.isLoading = false;
				this.$message.error(res.errorMessage);
			}
		},
		async booking() {},
	},
};
</script>

<style lang="less" scoped>
.reservation {
	padding: 20px 0;
	background-color: #f2f2f2;

	.reserve-wrap {
		width: 800px;
		margin: 0 auto;
		border-radius: 10px;
		background-color: #fff;
		padding: 50px 0;
	}

	.reserve-img,
	.reserve-title-img {
		display: block;
		margin: 0 auto;
	}

	.reserve-img {
		margin-bottom: 36px;
	}

	.reserve-title-img {
		margin-bottom: 43px;
	}

	.reserve-form {
		margin: 38px auto 0;
		width: 400px;

		/deep/.el-form-item__label {
			display: block;
			width: auto !important;
			float: none;
			text-align: left;
		}

		/deep/ .el-form-item__content {
			margin-left: 8px !important;
		}

		/deep/ .el-input__inner {
			border-radius: 10px;
			background: #f7f7f7;
			border: transparent;
		}

		/deep/ .el-input .el-input__count .el-input__count-inner {
			background-color: transparent;
		}

		/deep/.el-form-item.is-error .el-input__inner,
		.el-form-item.is-error .el-input__inner:focus,
		.el-form-item.is-error .el-textarea__inner,
		.el-form-item.is-error .el-textarea__inner:focus,
		.el-message-box__input input.invalid,
		.el-message-box__input input.invalid:focus {
			border: 1px solid #df0629;
		}

		/deep/ .el-radio {
			display: block;
			margin-bottom: 20px;

			&:last-child {
				margin-bottom: 0;
			}

			.el-radio__inner {
				border-radius: 4px;
				background-color: rgba(247, 247, 247, 1);
				border: 1px solid transparent;
				// border: 0;
				position: relative;

				&:hover {
					border: 1px solid rgba(1, 173, 237, 1);
				}

				&::after {
					box-sizing: content-box;
					content: "";
					border: 1px solid #fff;
					border-left: 0;
					border-top: 0;
					height: 7px;
					left: 4px;
					position: absolute;
					top: 1px;
					transform: rotate(45deg) scaleY(0);
					width: 3px;
					transition: transform 0.15s ease-in 0.05s;
					transform-origin: center;
					display: block;
					background-color: transparent;
					border-radius: 0;
				}
			}
			.el-radio__input.is-checked .el-radio__inner::after {
				transform: rotate(45deg) scaleY(1);
			}

			.el-radio__input.is-checked .el-radio__inner {
				background-color: rgba(1, 173, 237, 1);
			}
		}

		.submit-btn {
			margin: 83px auto 0;
			display: block;
			padding: 0 36px;
			height: 36px;
			line-height: 36px;
			border-radius: 40px;
			background: #01aded;
			color: #fff;

			&:hover {
				background-color: rgba(1, 173, 237, 0.7);
			}
		}
	}
}
</style>

import { get, post, url } from "../http";

class Cameras {
	// 查询机位列表
	@url("/cameras/my")
	@get
	getCamerasList() {}
	// 查询关注机位列表
	@url("/cameras/following")
	@get
	getFollowedList() {}
	// 发现页面列出视角
	@url("/cameras/discover")
	@get
	getDiscoverList() {}
	// 获取机位详情
	@url("/camera/info")
	@get
	getCamerasInfo() {}
	// 修改机位名称
	@url("/camera/name")
	@post
	updateCameraName() {}
	// 根据时间区间查(时长)询时间间隔
	@url("/camera/time-interval")
	@get
	getCameraInterval() {}
	// 机位图片信息
	@url("/camera/viewports")
	@get
	getCameraViewports() {}
	// 留言列表
	@url("/camera/message/list")
	@get
	getMessageList() {}
	// 留言精选操作
	@url("/camera/message/set-show")
	@post
	setShowMessage() {}
	// 查询机位留言方式
	@url("/camera/message/mode")
	@get
	getMessageMode() {}
	// 设置机位留言方式
	@url("/camera/message/mode")
	@post
	setMessageMode() {}
	// 对图片进行天气识别
	@url("/camera/weather-classify")
	@post
	weatherRecognition() {}
	// 查询生成视频需要消耗的积分
	@url("/camera/video/consumption")
	@get
	requestConsumption() {}
	// 生成视频
	@url("/camera/video/make")
	@post
	videoMake() {}
	// 影集列表
	@url("/camera/video/list")
	@get
	getAlbumList() {}
	// 机位异常样例图片
	@url("/camera/ab-sample")
	@get
	getAbSample() {}
	// 绑定机位
	@url("/camera/bind")
	@post
	cameraBind() {}
	// SN码检查
	@url("/camera/verify-sn")
	@post
	verifySNcode() {}
	// 解绑机位
	@url("/camera/unbind")
	@post
	unbind() {}
	// 关注他人视角
	@url("/camera/follow")
	@post
	follow() {}
	// 取消关注他人视角
	@url("/camera/unfollow")
	@post
	unfollow() {}
	// 机位点赞
	@url("/camera/like")
	@post
	like() {}
	// 修改机位位置
	@url("/camera/location")
	@post
	editLocation() {}
	// 查询机位位置
	@url("/camera/location")
	@get
	getLocation() {}

}

export default new Cameras();

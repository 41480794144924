var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "view" }, [
    _c(
      "div",
      { staticClass: "top-media" },
      [
        _c("div", { ref: "back", staticClass: "back" }, [
          _c(
            "button",
            {
              staticClass: "back-btn border-btn",
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("返回")]
          ),
        ]),
        _c(
          "el-skeleton",
          { attrs: { loading: _vm.skeletonLoading, animated: "" } },
          [
            _c("template", { slot: "template" }, [
              _c("div", { staticClass: "skeleton" }, [
                _c(
                  "div",
                  { staticClass: "media-info skeleton-mode" },
                  [
                    _c("el-skeleton-item", {
                      staticStyle: {
                        width: "30%",
                        display: "inlin-block",
                        "margin-right": "20px",
                      },
                      attrs: { variant: "p" },
                    }),
                    _c(
                      "div",
                      { staticStyle: { "margin-top": "23px" } },
                      [
                        _c("el-skeleton-item", {
                          staticStyle: {
                            width: "15%",
                            display: "inlin-block",
                            "margin-right": "20px",
                          },
                          attrs: { variant: "p" },
                        }),
                        _c("el-skeleton-item", {
                          staticStyle: {
                            width: "15%",
                            display: "inlin-block",
                            "margin-right": "20px",
                          },
                          attrs: { variant: "p" },
                        }),
                        _c("el-skeleton-item", {
                          staticStyle: { width: "15%", display: "inlin-block" },
                          attrs: { variant: "p" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            [
              _c("div", { staticClass: "media-info" }, [
                _c(
                  "div",
                  { staticClass: "flex-align-between" },
                  [
                    _vm.cameraInfo.role == "owner"
                      ? _c(
                          "div",
                          {
                            staticClass: "info-title",
                            on: {
                              mouseenter: _vm.toggleEditBtn,
                              mouseleave: _vm.toggleEditBtn,
                            },
                          },
                          [
                            !_vm.titleEditing
                              ? _c(
                                  "span",
                                  { staticClass: "content name-content" },
                                  [_vm._v(_vm._s(_vm.titleValue || "昵称"))]
                                )
                              : _vm._e(),
                            _vm.titleEditBtn && !_vm.titleEditing
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "user-edit-btn csp",
                                    on: { click: _vm.startEdit },
                                  },
                                  [_vm._v("修改")]
                                )
                              : _vm._e(),
                            _vm.titleEditing
                              ? _c(
                                  "div",
                                  { staticClass: "user-edit" },
                                  [
                                    _c("el-input", {
                                      staticClass: "edit-input",
                                      attrs: {
                                        placeholder: "请填写机位名称",
                                        maxlength: "32",
                                        "show-word-limit": "",
                                      },
                                      model: {
                                        value: _vm.titleValue,
                                        callback: function ($$v) {
                                          _vm.titleValue = $$v
                                        },
                                        expression: "titleValue",
                                      },
                                    }),
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "edit-submit-btn",
                                        attrs: { type: "primary" },
                                        on: { click: _vm.submitEdit },
                                      },
                                      [_vm._v("提交")]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        staticClass: "edit-cancel-btn",
                                        on: { click: _vm.cancelEdit },
                                      },
                                      [_vm._v("取消")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        )
                      : _c("div", { staticClass: "info-title" }, [
                          !_vm.titleEditing
                            ? _c(
                                "span",
                                { staticClass: "content name-content" },
                                [_vm._v(_vm._s(_vm.titleValue || "昵称"))]
                              )
                            : _vm._e(),
                        ]),
                    _c(
                      "el-button",
                      {
                        staticClass: "refresh-btn border-btn",
                        attrs: { icon: "el-icon-refresh-right" },
                        on: { click: _vm.updateView },
                      },
                      [_vm._v(" 刷新")]
                    ),
                  ],
                  1
                ),
                _c("div", { staticStyle: { "margin-top": "23px" } }, [
                  _c(
                    "div",
                    {
                      staticClass: "info-likes csp",
                      on: { click: _vm.follow },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/camera/like.svg"),
                          alt: "",
                        },
                      }),
                      _vm._v(_vm._s(_vm.cameraInfo.followers) + " "),
                    ]
                  ),
                  _vm.cameraInfo.role == "owner"
                    ? _c(
                        "div",
                        {
                          staticClass: "info-location",
                          on: {
                            mouseenter: _vm.toggleLocationBtn,
                            mouseleave: _vm.toggleLocationBtn,
                          },
                        },
                        [
                          !_vm.locationEditing
                            ? _c("img", {
                                attrs: {
                                  src: require("@/assets/images/camera/location.svg"),
                                  alt: "",
                                },
                              })
                            : _vm._e(),
                          !_vm.locationEditing
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.cameraInfo.location)),
                              ])
                            : _vm._e(),
                          _vm.locationEditBtn && !_vm.locationEditing
                            ? _c(
                                "button",
                                {
                                  staticClass: "user-edit-btn csp",
                                  on: {
                                    click: function ($event) {
                                      _vm.locationEditing = true
                                    },
                                  },
                                },
                                [_vm._v(" 修改 ")]
                              )
                            : _vm._e(),
                          _vm.locationEditing
                            ? _c(
                                "div",
                                { staticClass: "user-edit" },
                                [
                                  _c("el-cascader", {
                                    staticStyle: { width: "350px" },
                                    attrs: {
                                      placeholder: "请选择您的位置",
                                      options: _vm.cityOptions,
                                      popperClass: "city-choose",
                                      "append-to-body": false,
                                      props: { value: "label", emitPath: true },
                                    },
                                    on: { change: _vm.cityChange },
                                    model: {
                                      value: _vm.locationValue,
                                      callback: function ($$v) {
                                        _vm.locationValue = $$v
                                      },
                                      expression: "locationValue",
                                    },
                                  }),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "edit-submit-btn",
                                      attrs: { type: "primary" },
                                      on: { click: _vm.submitLocationEdit },
                                    },
                                    [_vm._v("提交")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      staticClass: "edit-cancel-btn",
                                      on: { click: _vm.cancelLocationEdit },
                                    },
                                    [_vm._v("取消")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      )
                    : _c("div", { staticClass: "info-location" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/camera/location.svg"),
                            alt: "",
                          },
                        }),
                        _c("span", [_vm._v(_vm._s(_vm.cameraInfo.location))]),
                      ]),
                  _c("div", { staticClass: "info-user" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/camera/user.svg"),
                        alt: "",
                      },
                    }),
                    _vm._v("@" + _vm._s(_vm.cameraInfo.owner_user_name)),
                  ]),
                ]),
              ]),
            ],
          ],
          2
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.mediaLoading,
                expression: "mediaLoading",
              },
            ],
            staticClass: "media-video",
          },
          [
            !_vm.notViews
              ? _c("div", { staticClass: "media-video-wrap" }, [
                  _c("img", {
                    staticClass: "frame",
                    attrs: { src: _vm.frameUrl, alt: "" },
                  }),
                  !_vm.mediaLoading
                    ? _c(
                        "div",
                        { staticClass: "video-marks flex-column-between" },
                        [
                          _c("div", { staticClass: "marks-top flex-between" }, [
                            _c("div", { staticClass: "top-left flex-align" }, [
                              _vm.logoMark
                                ? _c("img", {
                                    staticClass: "video-logo",
                                    attrs: {
                                      src: require("@/assets/images/camera/logo.svg"),
                                      alt: "",
                                    },
                                  })
                                : _vm._e(),
                              _vm.timeMark
                                ? _c("div", { staticClass: "video-time" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          JSON.stringify(_vm.viewPortsData) !==
                                            "[]" &&
                                            _vm
                                              .dayjs(
                                                _vm.viewPortsData.timestamps[
                                                  _vm.currentFrame
                                                ] * 1000
                                              )
                                              .utc()
                                              .local()
                                              .format("YYYY-MM-DD THH:mm:ssZ")
                                        ) +
                                        " "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.userMark
                                ? _c("div", { staticClass: "video-user" }, [
                                    _vm._v(
                                      "@" +
                                        _vm._s(_vm.cameraInfo.owner_user_name)
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "top-right video-good csp",
                                on: { click: _vm.likeCamera },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/camera/good.svg"),
                                    alt: "",
                                  },
                                }),
                                _c("p", [_vm._v(_vm._s(_vm.cameraInfo.likes))]),
                              ]
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "marks-bottom flex-between" },
                            [
                              _c("div", [
                                _c("div", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        JSON.stringify(_vm.commentList) !==
                                        "[]",
                                      expression:
                                        "JSON.stringify(commentList) !== '[]'",
                                    },
                                  ],
                                  staticClass: "video-comment-wrap",
                                }),
                              ]),
                              _vm.weatherMark
                                ? _c("div", { staticClass: "video-wetaher" }, [
                                    _c("div", { staticClass: "wether-item" }, [
                                      _c("img", {
                                        attrs: {
                                          src: require("@/assets/images/camera/weather.svg"),
                                          alt: "",
                                        },
                                      }),
                                      _c("span", [
                                        _vm._v(
                                          "天气类型：" +
                                            _vm._s(
                                              JSON.stringify(
                                                _vm.viewPortsData
                                              ) !== "[]" &&
                                                _vm.viewPortsData.weather[
                                                  _vm.currentFrame
                                                ]
                                            )
                                        ),
                                      ]),
                                    ]),
                                    JSON.stringify(_vm.viewPortsData) !==
                                      "[]" && _vm.viewPortsData.visibility
                                      ? _c(
                                          "div",
                                          { staticClass: "wether-item" },
                                          [
                                            _c("img", {
                                              staticClass: "weather-views",
                                              attrs: {
                                                src: require("@/assets/images/camera/views.svg"),
                                                alt: "",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "weather-text" },
                                              [
                                                _vm._v(
                                                  "能见度：" +
                                                    _vm._s(
                                                      _vm.viewPortsData
                                                        .visibility[
                                                        _vm.currentFrame
                                                      ]
                                                    )
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c("p", { staticClass: "tips" }, [
                                      _vm._v("以上天气信息基于AI图像识别"),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ])
              : _c("div", { staticClass: "media-video-wrap" }, [
                  _c("img", {
                    staticClass: "frame",
                    attrs: { src: _vm.sampleImage, alt: "" },
                  }),
                  _c("p", { staticClass: "notViews-tips" }, [
                    _vm._v("所选时间段内暂无数据"),
                  ]),
                ]),
            !_vm.notViews
              ? _c("progressBar", {
                  attrs: { progress: _vm.progress, isPlaying: _vm.isPlaying },
                  on: {
                    updateFrame: _vm.updateCurrentFrame,
                    isPlay: _vm.playFrame,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "bottom-setting" }, [
      _c(
        "div",
        { staticClass: "nav-bar" },
        [
          _vm._l(_vm.items, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "nav-item-container",
                on: {
                  click: function ($event) {
                    return _vm.selectItem(index)
                  },
                },
              },
              [_c("div", { staticClass: "nav-item" }, [_vm._v(_vm._s(item))])]
            )
          }),
          _c("div", {
            staticClass: "underline",
            style: { transform: `translateX(${_vm.currentIndex * 100}%)` },
          }),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(_vm.currentComponent, {
            tag: "component",
            attrs: {
              controlTime: _vm.controllerTime,
              isPlay: _vm.isPlaying,
              refresh: _vm.isRefresh,
              frame: _vm.frameUrl,
            },
            on: { changeCheck: _vm.changeMarks, timePicke: _vm.changeTime },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
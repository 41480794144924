import store from "../vuex/store";
// 用户

const coummunity = () => import("../views/community/coummunity.vue"); // 社区入口文件
const discover = () => import("../views/community/discover/index.vue"); // 发现主页
const my= () => import("../views/community/my/index.vue"); // 我的主页
const camera = () => import("../views/community/camera/camera.vue"); // 视角详情页
const binding = () => import("../views/community/my/binding.vue"); // 视角绑定页

const perspective = () => import("../views/community/my/collection/perspective.vue"); //视角列表页
const album = () => import("../views/community/my/collection/album.vue"); //影集列表页
const follow = () => import("../views/community/my/collection/follow.vue"); //关注列表页
const points = () => import("../views/community/my/collection/points.vue"); //积分页
const achievements = () => import("../views/community/my/collection/achievements.vue"); //成就页

export default [
	{
		path: "/coummunity",
		name: "coummunity",
		component: coummunity,
		redirect: { name: "discover" },
		meta: {
			keepAlive: false,
		},
		children: [
			{
				path: "discover",
				name: "discover",
				component: discover,
				meta: {
					keepAlive: false,
					title: "发现",
				},
			},
			{
				path: "my",
				name: "my",
				component: my,
				meta: {
					title: "我的主页",
					keepAlive: false,
					redirect: 'perspective',
				},
				children: [
					{
						path: "perspective",
						name: "perspective",
						component: perspective,
						meta: {
							title: "我的主页",
							name: "视角",
							keepAlive: false,
						},
					},
					{
						path: "album",
						name: "album",
						component: album,
						meta: {
							title: "我的主页",
							name: "影集",
							keepAlive: false,
						},
					},
					{
						path: "follow",
						name: "follow",
						component: follow,
						meta: {
							title: "我的主页",
							name: "关注",
							keepAlive: false,
						},
					},
					{
						path: "points",
						name: "points",
						component: points,
						meta: {
							title: "我的主页",
							name: "积分",
							keepAlive: false,
						},
					},
					{
						path: "achievements",
						name: "achievements",
						component: achievements,
						meta: {
							title: "我的主页",
							name: "成就",
							keepAlive: false,
						},
					},
				],
			},
			{
				path: "/binding",
				name: "binding",
				component: binding,
				meta: {
					title: "视角绑定",
					keepAlive: false,
				},
			},
			{
				path: "/camera/:id",
				name: "camera",
				component: camera,
				meta: {
					keepAlive: false,
				},
			},
		],
	},
];

<template>
	<div class="empty">
		<div class="empty-wrap">
			<img src="@/assets/images/common/empty.svg" alt="" class="empty-image" />
			<div class="empty-text">
				<slot></slot>
			</div>
		</div>
	</div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.empty {
	text-align: center;
	box-sizing: border-box;
	position: relative;
	flex-grow: 1;
	
	.empty-wrap {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.empty-image {
		margin-bottom: 20px;
	}

	.empty-text {
		button {
			font-weight: 600;
			color: #01aded;
			cursor: pointer;

			&:hover {
				color: rgba(1, 173, 237, 0.7);
			}
		}
	}
}
</style>

import axios from "axios";
import DoApi from "./utils/DoApi";
import store from "@/vuex/store";
import Cookies from "js-cookie";

axios.defaults.timeout = 10000;
console.log(process.env.NODE_ENV);
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

//http request 请求拦截器
axios.interceptors.request.use(
	(config) => {
		let token = Cookies.get("access_token");
		// config.data = JSON.stringify(config.data);
		config.headers = {
			"Content-Type": "application/json; charset=utf-8",
		};
		if (token) {
			config.headers["Authorization"] = "Bearer " + token;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);
// 重置方法 
// 清空cookie,显示登录框,重置vuex
const resetFunc = () => {
	Cookies.remove("access_token");
	Cookies.remove("refresh_token");
	store.dispatch("resetState");
	store.state.login.visibleLogin = true;
};
// 请求队列
let pendingRequests = [];

//http response 响应拦截器
axios.interceptors.response.use(
	(response) => {
		return response;
	},
	async (error) => {
		if (error.response.status) {
			let refreshToken = Cookies.get("refresh_token");
			const originalRequest = error.config;
			switch (error.response.status) {
				// 401:accesstoken 过期或者无效
				case 401:
					// 请求的是Login接口，直接拒绝
					if (originalRequest.url.includes("/auth/login")) {
						return Promise.reject(error);
					}
					// 请求的是refresh接口 && refreshToken 存在
					// 表明refreshToken失效了 需重新登录
					if (originalRequest.url.includes("/auth/refresh") && refreshToken) {
						VueInstance.$confirm("登录信息已过期，请重新登录", "系统提示", {
								confirmButtonText: "确定",
								cancelButtonText: "取消",
								type: "warning",
							})
							.then(() => {
								// 重新登录
								resetFunc();
							})
							.catch(() => {})
							.finally(() => {
								store.commit("setRefreshing", false);
							});
						return Promise.reject(error);
					}
					// 刷新token逻辑
					// 非重试 && 非refresh接口
					if (!originalRequest._retry && !originalRequest.url.includes("/auth/refresh")) {
						// 没在刷新中
						if (!store.state.user._isRefreshing) {
							store.commit("setRefreshing", true);
							try {
								// refreshToken存在
								// 发送请求刷新accessToken
								if (refreshToken) {
									const resData = await Api.Login.refreshToken({
										refresh_token: Cookies.get("refresh_token"),
									});
									// console.log('resData',resData.data)
									store.commit("getToken", {
										access_token: resData.data.access_token,
										refresh_token: Cookies.get("refresh_token"),
									});
									Cookies.set("access_token", resData.data.access_token);
									// 将新的 token 添加到原始请求头部
									axios.defaults.headers.common["Authorization"] = "Bearer " + resData.data.access_token;
									// 重置刷新状态
									store.commit("setRefreshing", false);
									// 重新尝试所有被拦截的请求
									pendingRequests.forEach(({
										_originalRequest,
										_resolve,
										_reject
									}) => {
										// console.log("request 1", _originalRequest);
										// console.log("resolve 1", _resolve);
										if(typeof _originalRequest.data == 'string') {
											_originalRequest.data = JSON.parse(_originalRequest,data)
										}
										console.log(_originalRequest.data)
										_resolve(axios(_originalRequest));
									});

									return axios(originalRequest);

								} else {
									resetFunc();
								}
							} catch (error) {
								// console.log("responseError", error);
							} finally {
								pendingRequests = [];
							}
						}
						// 如果刷新正在进行中，将当前请求加入队列等待处理
						return new Promise((resolve, reject) => {
							originalRequest._retry = true;
							let _originalRequest = originalRequest;
							let _resolve = resolve;
							let _reject = reject;
							pendingRequests.push({
								_originalRequest,
								_resolve,
								_reject
							});

						});
					} else {
						// 如果是重复的刷新token请求或其它情况，直接拒绝
						return Promise.reject(error);
					}
					default:
						return Promise.reject(error.response);
			}
		}
		return Promise.reject(error.response);
	}
);
// api装饰器
function send(target, name, descriptor, callback) {
	descriptor.value = function () {
		return callback(arguments[0]);
	};
	return descriptor;
}

// 添加 路径
function url(url) {
	return function (target, name, descriptor) {
		descriptor.url = url;
	};
}

function get(target, name, descriptor) {
	return send(target, name, descriptor, function (args, url) {
		return axios.get(descriptor.url + "?" + DoApi.jsonUrlFormat(args));
	});
}

function post(target, name, descriptor) {
	return send(target, name, descriptor, function (args) {
		// return axios.post(descriptor.url + '?' + DoApi.jsonUrlFormat({}), DoApi.doJson(args));
		return axios.post(descriptor.url + "?" + DoApi.jsonUrlFormat({}), DoApi.doJson(args));
	});
}

function fetch(target, name, descriptor) {
	return send(target, name, descriptor, function (args) {
		return axios.get(descriptor.url + args);
	});
}

export {
	get,
	url,
	post,
	fetch
};
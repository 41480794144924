// 用户

const Home = () => import("../views/home/home.vue");
const notFound = () => import("../views/404.vue");
const userAgreement = () => import("../views/agreement/userAgreement.vue");
const bestPlan = () => import("../views/agreement/best_plan.vue");
const pointGuide = () => import("../views/agreement/point_guide.vue");
const reservation = () => import("../views/reservation.vue");

export default [
	{
		path: "/",
		redirect: "/home",
	},
	{
		path: "/home",
		name: "home",
		component: Home,
		meta: {
			keepAlive: false,
		},
	},
	{
		path: "/reservation",
		name: "reservation",
		component: reservation,
		meta: {
			title: "预定观天派",
			keepAlive: false,
		},
	},
	{
		path: "/agreement",
		name: "agreement",
		component: userAgreement,
		meta: {
			title: "用户协议",
			keepAlive: false,
		},
	},
	{
		path: "/bestPlan",
		name: "bestPlan",
		component: bestPlan,
		meta: {
			title: "精选计划",
			keepAlive: false,
		},
	},
	{
		path: "/pointGuide",
		name: "pointGuide",
		component: pointGuide,
		meta: {
			title: "积分手册",
			keepAlive: false,
		},
	},
	{
		path: "*",
		name: "notFound",
		component: notFound,
		meta: {
			title: "404",
			keepAlive: false,
		},
	},
];
